/**
 * Master Microservice
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ApplicationModel { 
    applicationId?: number;
    applicationName: string;
    applicationLabel: string;
    description?: string | null;
    modifiedBy?: string | null;
    isActive?: boolean;
    isVerfied?: boolean;
    applicationMetadata: string;
    applicationType?: string | null;
    logoUrl?: string | null;
    mapping?: string | null;
}

