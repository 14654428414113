/**
 * Master Microservice
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface MessageTemplateModel { 
    messageTemplateID?: number;
    subject: string;
    body: string;
    templateType: string;
    templateName: string;
    templateDisplayName: string;
    isActive: boolean;
    createdBy: string;
    modifiedBy: string;
    createdDate: string;
    modifiedDate: string;
}

