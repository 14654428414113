/**
 * Identity Server Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface UserSessionViewModel { 
    id?: string;
    location?: string | null;
    platform?: string | null;
    browser?: string | null;
    isMobile?: boolean;
    lastActive?: string | null;
    isCurrent?: boolean;
}

