/**
 * SecurEnvoy.Migration
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ConditionalAccessData } from './conditionalAccessData';
import { OrganizationData } from './organizationData';
import { UserData } from './userData';
import { IdentityData } from './identityData';


export interface AllData { 
    identity?: IdentityData;
    conditionalAccess?: ConditionalAccessData;
    organization?: OrganizationData;
    user?: UserData;
}

