import { Component, OnInit, ViewChild } from '@angular/core';
import { ConstantService, ThemeEnum } from '../../../services/common/constant.service';
import { UpdateLicenceComponent } from '../update-licence/update-licence.component';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-update-expired',
  templateUrl: './update-expired.component.html',
  styleUrls: ['./update-expired.component.scss']
})
export class UpdateExpiredComponent implements OnInit {

  private logoImage: string;

  private isSaving: boolean = false;
  private isDisabled: boolean = false;
  private method: string;
  private useFile: boolean = false;
  private orgName: string;
  private saveComplete: boolean = false;

  @ViewChild('updateLicence') updateLicenceComponent: UpdateLicenceComponent;

  constructor(
    private constantService: ConstantService,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    const logoImage = this.constantService.getSessionStorage(ThemeEnum.mainLogo);
    this.logoImage = logoImage ?? "assets/images/se-white-logo.png";

    const routeParams = this.activatedRoute.snapshot.params;
    this.method = routeParams.method;
    this.useFile = this.method == 'file';
    this.orgName = routeParams.orgName;
  }

  saveDisabledChanged(isDisabled: boolean) {
    this.isDisabled = isDisabled;
  }

  async save() {
    this.isSaving = true;
    try {
      let result = await this.updateLicenceComponent.updateLicenceSource(false);
      this.isSaving = false;
      if (result) {
        this.saveComplete = true;
        this.isDisabled = true;
      }
    }
    catch {
      this.isSaving = false;
    }
  }

}
