import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';

import { ConfirmDialogComponent } from './confirm-dialog.component';
import { TranslateModule } from '@ngx-translate/core';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [ConfirmDialogComponent],
  imports: [CommonModule, TranslateModule],
  exports: [ConfirmDialogComponent],
  providers: [NgbActiveModal],
})
export class ConfirmDialogModule {}
