import { Injectable, NgModule } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AppSettings } from './appsettings.model';
import { AppSettingsService } from './appsettings.service';

@NgModule({
  declarations: [],
  imports: [],
  providers: [AppSettingsService],
})
export class SettingsService {
  public static config: [{ key; value }];

  constructor(public appSettings: AppSettingsService) {
    this.setDefaults();
  }

  private static getConfig() {
    if (SettingsService.config == null) {
      SettingsService.config = SettingsService.getDataSynchronous();
    }

    return SettingsService.config;
  }

  private setDefaults() {
    //let isDevMode: boolean = window.location.host.indexOf('localhost') >= 0;
    const config = SettingsService.getConfig();

    if (!config) {
      return;
    }

    let publicUrl = config.find((k) => k.key == 'PublicUrl')?.value;
    environment.publicUrl = '.' + publicUrl;
    environment.reCaptchaSiteKey = config.find(
      (k) => k.key == 'PublicCaptchaSiteKey'
    )?.value;

    const appUrl = publicUrl + this.appSettings.settings.rootUrl;
    let orgCodePlaceholder = '{org_code}.';
    environment.azureLoginUri = config.find((k) => k.key == 'PublicAzureLoginUri')?.value;
    environment.azureClientId = config.find((k) => k.key == 'PublicAzureClientId')?.value;
    environment.azureRedirect = 'https://' + publicUrl + environment.azureRedirect;
    environment.contactUrl = config.find((k) => k.key == 'PublicContactUrl')?.value;

    environment.googleDirectoryAllowedOrigin = 'https://' + publicUrl;
    environment.googleDirectoryClientId = config.find((k) => k.key == 'GoogleClientId')?.value;
    environment.googleDirectoryClientSecret = config.find((k) => k.key == 'GoogleClientSecret')?.value;

    environment.downloadcookie_domain = publicUrl;

    let singleTenant = config.find((k) => k.key == 'SingleTenant')?.value;
    if (singleTenant && singleTenant.length > 0) {
      environment.singleTenantMode = true;
      environment.organizationCode = singleTenant;
      orgCodePlaceholder = '';
    } else {
      environment.singleTenantMode = false;
    }

    var value = config.find( v=> v.key == "Environment")?.value ?? 'Production';
    switch (value) {
      case 'OnPremises':
        environment.organization = 'https://' + publicUrl + ':3002';
        environment.user = 'https://' + publicUrl + ':3005';
        environment.master = 'https://' + publicUrl + ':3004';
        environment.activityLogging = 'https://' + publicUrl + ':3001';
        environment.analytics = 'https://' + publicUrl + ':3008';
        environment.filestore = 'https://' + publicUrl + ':3011';
        environment.notification = 'https://' + publicUrl + ':3007';
        environment.conditionalAccess = 'https://' + publicUrl + ':3006';
        environment.identity = 'https://' + orgCodePlaceholder + publicUrl + '/identity';
        environment.migration = 'https://' + publicUrl + ':3012';
  
        environment.identityApi = environment.identity;
        environment.identityRedirect = 'https://' + orgCodePlaceholder + appUrl + '/auth-callback';
        environment.identityChangePassword = 'https://' + orgCodePlaceholder + appUrl + '/auth-callback&changePassword=true';
        environment.identityLogout = 'https://' + orgCodePlaceholder + appUrl + '/auth/logout';
        environment.identitySilentRedirect = 'https://' + orgCodePlaceholder + appUrl + '/silent-refresh';
        environment.identityInitiatedUrl = environment.identity + '/saml2?app=$app_id$&ServiceProvider=$thirdPartyEntityId$&AssertConsumerUrl=$thirdPartySignInUrl$';
            
        break;
    
      case 'Production':
        environment.organization = 'https://' + publicUrl + '/api/organization';
        environment.user = 'https://' + publicUrl + '/api/user';
        environment.master = 'https://' + publicUrl + '/api/master';
        environment.activityLogging = 'https://' + publicUrl + '/api/activitylogging';
        environment.analytics = 'https://' + publicUrl + '/api/analytics';
        environment.filestore = 'https://' + publicUrl + '/api/filestore';
        environment.notification = 'https://' + publicUrl + '/notification';
        environment.conditionalAccess = 'https://' + publicUrl + '/api/conditionalaccess';
        environment.identity = 'https://' + orgCodePlaceholder + publicUrl + '/identity';
        environment.migration = 'https://' + publicUrl + '/api/migration';
        environment.report = 'https://' + publicUrl + '/api/report';
  
        environment.identityApi = 'https://' + orgCodePlaceholder + publicUrl + '/identity';
        environment.identityRedirect = 'https://' + orgCodePlaceholder + appUrl + '/auth-callback';
        environment.identityChangePassword = 'https://' + orgCodePlaceholder + appUrl + '/auth-callback&changePassword=true';
        environment.identityLogout = 'https://' + orgCodePlaceholder + appUrl + '/auth/logout';
        environment.identitySilentRedirect = 'https://' + orgCodePlaceholder + appUrl + '/assets/silent-renew.html';
        environment.identityInitiatedUrl = 'https://' + orgCodePlaceholder + publicUrl + '/identity/saml2?app=$app_id$&ServiceProvider=$thirdPartyEntityId$&AssertConsumerUrl=$thirdPartySignInUrl$';
  
        break;
    }

  }

  static getDataSynchronous() {
    try {
          var request = new XMLHttpRequest();
          request.open('GET', '/settings', false);
      
          request.send();
      
          if (request.status === 200) {
            return JSON.parse(request.response);
          }
    } catch (error) {
      console.log('Error getting settings', error);
    }
  }
}
