/**
 * Organization Microservice
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { OrganizationQuestionSecretModelBinding } from './organizationQuestionSecretModelBinding';


export interface OrganizationQuestionsModelBinding { 
    organizationQuestions?: Array<OrganizationQuestionSecretModelBinding> | null;
    askQuestionSecret?: boolean;
    noOfConfigurableQuestionSecret?: number;
    noOfAskableQuestionSecret?: number;
}

