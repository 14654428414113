import { Component, OnDestroy, OnInit, Input } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { MyAppGroupService } from 'src/app/services/user';

@Component({
  selector: 'app-delete-group-modal',
  templateUrl: './delete-group-modal.component.html',
  styleUrls: ['./delete-group-modal.component.scss']
})
export class DeleteGroupModalComponent implements OnInit {
  @Input() myAppGroupId;

  constructor(private fb: FormBuilder,
    private toastrService: ToastrService,
    private translateService: TranslateService,
    public activeModal: NgbActiveModal,
    private myAppGroupService: MyAppGroupService) { }

  ngOnInit(): void {
  }
  delete() {
    this.myAppGroupService.apiMyAppGroupDeleteMyAppGroupByIdMyAppGroupIdDelete(this.myAppGroupId).subscribe({
      next: data => {
        if (data) {
          this.toastrService.success(this.translateService.instant('Action.Success'));
          this.activeModal.close(true);
        }
      },
    })
  }

}
