/**
 * Organization Microservice
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { GroupDirectory } from './groupDirectory';


export interface Group { 
    groupID?: number;
    groupName?: string | null;
    description?: string | null;
    sourceOrganizationId?: number;
    organizationID?: number;
    mnemonic?: string | null;
    createdBy?: string | null;
    createdDate?: string;
    modifiedBy?: string | null;
    modifiedByDirectoryID?: number | null;
    modifiedDate?: string;
    isActive?: boolean;
    distinguishedName?: string | null;
    isDeleted?: boolean;
    sourceDirectoryID?: number | null;
    groupDirectory?: Array<GroupDirectory> | null;
    isEnforcedSecondFactor?: boolean | null;
}

