import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChild, UrlTree } from '@angular/router';
import { AuthService } from 'src/app/authentication/auth.service';
import { HasPermissionService } from './has-permission.service';
import { UserRoles } from './constant.service';

@Injectable({
    providedIn: 'root',
})
export class ActivateAdmin implements CanActivate, CanActivateChild {
    constructor(private router: Router, private hasPermission: HasPermissionService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
        var roles :UserRoles[] = [ UserRoles.HelpDeskUser, UserRoles.AdminUser, UserRoles.MspAdmin, UserRoles.SuperAdmin];
        return this.hasPermission.allowAccess(roles);
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
        return this.canActivate(route, state);
    }

}
