/**
 * Organization Microservice
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface Token { 
    access_token?: string | null;
    scope?: string | null;
    token_type?: string | null;
    issuedUTC?: string;
    expires_in?: string | null;
    code?: string | null;
    refresh_token?: string | null;
    lastRefreshed?: string;
}

