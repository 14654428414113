/**
 * Conditional Access Microservice
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface PolicyStatsFilterOptions { 
    dateFrom?: string | null;
    dateTo?: string | null;
    policyIds?: Array<string> | null;
    match?: string | null;
    results?: Array<string> | null;
    pageSize?: number;
    page?: number;
    sortColumn?: string | null;
}

