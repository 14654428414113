/**
 * Organization Microservice
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { MspChildReportViewModel } from './mspChildReportViewModel';


export interface MspReportViewModel { 
    dateAdded?: string;
    childReports?: Array<MspChildReportViewModel> | null;
    totalUsers?: number;
    totalAuthentications?: number;
    totalConfiguredApplications?: number;
    totalSentSms?: number;
}

