import { Component, OnInit } from '@angular/core';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { User } from 'oidc-client';
import { AuthService } from 'src/app/authentication/auth.service';
import { AppSettingsService } from 'src/app/services/common/appsettings.service';
import { OrganizationService } from 'src/app/services/organization';
import { environment } from 'src/environments/environment';
import differenceInDays from 'date-fns/differenceInDays';
import { parseISO } from 'date-fns';
import { ConstantService, ThemeEnum, UserRoles } from 'src/app/services/common/constant.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  public parentId = '';
  public scrollbarConfig: any;
  public user: User;
  public days = 10;
  public version: string;
  logoUrl: string;
  profileImageUrl: string;

  constructor(
    public appSettings: AppSettingsService,
    public authService: AuthService,
    public constantService: ConstantService,
    public organizationService: OrganizationService
  ) {
    this.version = environment.apiVersion;
  }

  async ngOnInit() {
    this.logoUrl = this.constantService.getImageFromServer(ThemeEnum.mainLogo) ?? "assets/images/logo.png";
    this.profileImageUrl = this.constantService.getImageFromServer(ThemeEnum.profileLogo) ?? "assets/images/user.png";

    const body = document.querySelector('body');

    // add class 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
    document.querySelectorAll('.sidebar .nav-item').forEach(function (el) {
      el.addEventListener('mouseover', function () {
        if (body!.classList.contains('sidebar-icon-only')) {
          el.classList.add('hover-open');
        }
      });
      el.addEventListener('mouseout', function () {
        if (body!.classList.contains('sidebar-icon-only')) {
          el.classList.remove('hover-open');
        }
      });
    });

    this.user = await this.authService.getUser();
    this.organizationService
      .apiOrganizationOrganizationLicenseByMnemonicMnemonicGet(this.constantService.getSubdomain(false))
      .subscribe((data) => {
        if (data) {
          const expiryDate = data.onTrial
            ? data.trialExpiryDate
            : data.licenseExpiryDate;
          this.days = differenceInDays(parseISO(expiryDate), new Date());
        }
      });
  }

  public isUser(){
    const userType = this.authService.userType;
    return (userType && userType.toLowerCase() === UserRoles.User.toLowerCase());
  }
  
  clickedMenu(event) {
    var target = event.currentTarget;
    let parentId = target.id;
    if (parentId == this.parentId) {
      this.parentId = '';
    } else {
      this.parentId = target.id;
    }
  }

  public home() {
    return this.authService.redirectUser();
  }
}
