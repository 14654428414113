import { Injectable } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { ConstantService } from "src/app/services/common/constant.service";
import { UserCredentialService } from "../../services/identity";
import { AuthService } from "../auth.service";

@Injectable({ providedIn: "root" })
export class SharedAuthenticationService {

  constructor(
    private authService: AuthService,
    private userCredentialService: UserCredentialService,
    private toastService: ToastrService,
    private constantService: ConstantService
  ) { }


  async getUserDetail(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.userCredentialService.apiUserCredentialGetUserPostLoginStatusGet().subscribe({
        next: (data) => {
          const userType = this.authService.userType;
          if (!userType) {
            this.toastService.error("Role not exists", "Role info", this.constantService.ToastError);
            this.delayedSignout();
            reject();
          }
          if (!data) {
            this.toastService.error("User Detail is not valid", "User info", this.constantService.ToastError);
            this.delayedSignout();
            reject();
          }
          this.authService.$enforceData.next(data);
          resolve()
        },
        error: () => {
          this.delayedSignout();
          reject();
        }
      });
    })
  }

  delayedSignout() {
    setTimeout(() => {
      this.authService.signOut();
    }, 3000);
  }
}
export interface GetUserDetailParameters {
  mustChangePassword?: boolean;
  questionSecretStatus?: string;
  msg?: string;
}
