import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';

import { ApplicationService } from './api/application.service';
import { CountryService } from './api/country.service';
import { DirectoryService } from './api/directory.service';
import { DownloadsService } from './api/downloads.service';
import { EmployeeTypeService } from './api/employeeType.service';
import { JobTitleService } from './api/jobTitle.service';
import { MessageTemplateService } from './api/messageTemplate.service';
import { PredefinedAttributeService } from './api/predefinedAttribute.service';
import { PredefinedAttributeMappingService } from './api/predefinedAttributeMapping.service';
import { QuestionSecretService } from './api/questionSecret.service';
import { RadiusServiceService } from './api/radiusService.service';
import { ReleaseNotesService } from './api/releaseNotes.service';
import { SystemSettingService } from './api/systemSetting.service';
import { VersionService } from './api/version.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: []
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
