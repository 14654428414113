/**
 * Organization Microservice
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface RadiusAttributeMappingModel { 
    radiusAttributeMappingId?: number;
    masterDirectoryId?: number;
    radiusClientId: number;
    vendorId?: number;
    vendorCode?: number;
    attributeCode: number;
    attributeType?: string | null;
    sourceColumnName?: string | null;
    isSourceCustomAttribute?: boolean;
    destinationColumnName?: string | null;
    udTableName?: string | null;
    isReadonly?: boolean;
    displayName?: string | null;
    attributeMetadataId?: number;
    modifiedBy?: string | null;
    modifiedDate?: string;
    isActive?: boolean;
}

