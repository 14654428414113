/**
 * User Microservice
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface UserGroupModel { 
    isSelect?: boolean;
    userId?: number;
    uniqueId?: string | null;
    groupId?: number;
    username?: string | null;
    email?: string | null;
    userType?: string | null;
    groupName?: string | null;
    modifiedBy?: string | null;
    createdBy?: string | null;
}

