/**
 * Organization Microservice
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ApplicationSettingModel { 
    applicationSettingId?: number;
    applicationId?: number | null;
    applicationName?: string | null;
    applicationLabel?: string | null;
    organizationId?: number;
    organizationMnemonic?: string | null;
    attributesValue: string;
    description?: string | null;
    isActive?: boolean;
    modifiedBy?: string | null;
    isDeleted?: boolean;
    isConfigured?: boolean;
    logoUrl?: string | null;
    mapping?: string | null;
    applicationMetadata?: string | null;
    organizationApplicationId?: string | null;
    status?: string | null;
}

