import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { Subscription, firstValueFrom } from 'rxjs';
import { ConstantService, ThemeEnum } from '../../services/common/constant.service';
import { SecuritySettingModel, SecuritySettingService } from '../../services/organization';
import { PasswordService, ResetPasswordModel, UserCredentialModel, UsersService } from '../../services/user';
import { CustomValidators } from '../../shared/validators/custom-validators';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../auth.service';
import { UserCredentialService } from '../../services/identity';
import { SharedAuthenticationService } from '../shared/shared-authentication.service';
import { TranslateService } from '@ngx-translate/core';
import { AccountService } from 'src/app/services/identity';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PasswordCompromisedModal } from 'src/app/my-profile/password-compromised-modal/password-compromised-modal';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit, OnDestroy {

  logoImage: string;
  changePasswordForm: FormGroup;
  userName: string;
  isSaving: boolean;
  url: any = this.location.path().split("/");
  userCode: string;
  isForgotPassword: boolean;
  organizationCode: string;
  isConfirmPassText: boolean;
  isPasswordText: boolean;
  securitySettings: SecuritySettingModel = {} as SecuritySettingModel;
  private subscriptions: Subscription = new Subscription;

  resetPasswordModel: ResetPasswordModel = {
    newPassword: null,
    userCode: null,
  };

  credentialModel: UserCredentialModel = {
    password: "",
    newPassword: "",
  };
  subDomain: string;
  isPassComplexityError: boolean;
  passComplexityErrorMsg: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private constantService: ConstantService,
    private authService: AuthService,
    private securitySettingService: SecuritySettingService,
    private location: Location,
    private resetPasswordService: PasswordService,
    private userCredentialService: UserCredentialService,
    private toastrService: ToastrService,
    private sharedService: SharedAuthenticationService,
    private translateService: TranslateService,
    private accountService: AccountService,
    private ngbModal: NgbModal,
  ) { }

  ngOnInit() {
    const logoImage = this.constantService.getSessionStorage(ThemeEnum.mainLogo);
    this.logoImage = logoImage ?? "assets/images/se-white-logo.png";

    this.createForm();

    this.userCode = this.activatedRoute.snapshot.params.userCode;

    if (this.url.indexOf("userCode") > -1 || this.userCode) {
      this.isForgotPassword = true;
      this.subDomain = this.constantService.getSubdomain(false);
    }

    if (this.isForgotPassword) {
      this.subscriptions.add(this.securitySettingService.apiSecuritySettingForgotsettingsbyorgidGet(this.subDomain).subscribe(data => this.handleSecuritySettings(data)));
    } else {
      this.subscriptions.add(this.securitySettingService.apiSecuritySettingSettingsbyorgidGet(0).subscribe(data => this.handleSecuritySettings(data)));
    }

  }

  private createForm(): void {
    this.changePasswordForm = new FormGroup({
      newPassword: new FormControl(""),
      confirmNewPassword: new FormControl(""),
      userCode: new FormControl("")
    },
      this.checkPasswords
    )
  }

  get passwordControl() {
    return this.changePasswordForm.controls.newPassword;
  }

  private checkPasswords(form: FormGroup) {
    const password = form.get("newPassword");
    const confirmPassword = form.get("confirmNewPassword");
    if (password.value === confirmPassword.value) {
      confirmPassword.setErrors(null);
      return null;
    }
    confirmPassword.setErrors({ 'passwordNotMatch': true });
    return { passwordNotMatch: true }
  }

  private handleSecuritySettings(data: SecuritySettingModel): void {
    this.securitySettings = data;

    let validators = [];
    if (this.securitySettings.pwdMustIncludeNumber) {
      validators.push(
        CustomValidators.patternValidator(/\d/, {
          hasNumber: true,
        })
      );
    }
    if (this.securitySettings.pwdMustIncludeUppercase) {
      validators.push(
        CustomValidators.patternValidator(/[A-Z]/, {
          hasCapitalCase: true,
        })
      );
    }
    if (this.securitySettings.pwdMustIncludeLowercase) {
      validators.push(
        CustomValidators.patternValidator(/[a-z]/, {
          hasSmallCase: true,
        })
      );
    }
    if (this.securitySettings.pwdMustIncludeSpecialCharacter) {
      validators.push(
        CustomValidators.patternValidator(
          /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/,
          {
            hasSpecialCharacters: true,
          }
        )
      );
    }
    if (this.securitySettings.passwordMinLength) {
      validators.push(
        Validators.minLength(this.securitySettings.passwordMinLength)
      );
    }
    validators.push(Validators.required);
    this.changePasswordForm.controls.newPassword.setValidators(validators)
  }

  /** Reset password main function */
  async resetPassword() {
    if (
      this.changePasswordForm.value.newPassword !==
      this.changePasswordForm.value.confirmNewPassword
    ) {
      return;
    }

    if(!await this.checkPasswordCompromised()) return;

    if (this.isForgotPassword) {
      this.changeForgotPassword();
    } else {
      this.changeFirstLoginPassword();
    }
  }

  async checkPasswordCompromised(): Promise<boolean>{
    const reply:any = {
      credential: {
        Type: "Password",
        UserInput: this.changePasswordForm.value.newPassword
      }
    };

    const data = await firstValueFrom(this.accountService.apiAccountCheckPasswordCompromisedPost(reply));
    if(data===true){
      const modalRef = this.ngbModal.open(PasswordCompromisedModal, { size: 'lg' });
      return await modalRef.result;
    }
    return true;
  }

  /** Change password after forgot password  */
  changeForgotPassword() {
    this.isSaving = true;
    this.isPassComplexityError = false;
    this.resetPasswordModel.newPassword = this.changePasswordForm.value.newPassword;
    this.resetPasswordModel.userCode = this.userCode;
    this.subscriptions.add(this.resetPasswordService
      .apiPasswordResetForgetPasswordPost(this.resetPasswordModel)
      .subscribe({
        next: (data) => {
          if (!data) {
            this.toastrService.info(
              "Password changed session has been expire, request again!",
              "Info"
            );
          } else {
            if (data.status === false) {
              this.passComplexityErrorMsg = data.message;
              this.isPassComplexityError = true;
            } else {
              this.toastrService.success(this.translateService.instant('Success.Password.PasswordUpdated'), this.translateService.instant('Action.Success'),
              );
              this.authService.login();
            }
          }
          this.isSaving = false;
        },
        error: (error) => {
          if (error.error === "Password history policy failed") {
            this.toastrService.info(
              "Please choose password other then previously used",
              "Password History"
            );
          } else {
            this.toastrService.error(this.translateService.instant(error.error), this.translateService.instant('Action.Error'), this.constantService.ToastError);
          }
          this.isSaving = false;
        }
      }));
  }

  /** Change user first login password  */
  changeFirstLoginPassword() {
    if (this.changePasswordForm.invalid) {
      return;
    }
    this.isSaving = true;
    this.credentialModel.newPassword = this.changePasswordForm.value.newPassword;
    this.subscriptions.add(this.userCredentialService
      .apiUserCredentialResetUserPasswordPost(this.credentialModel)
      .subscribe(async data => {
        if (data.status !== true) {
          if (data.message == "Error.Password.PasswordLastUsedError") {
            this.toastrService.error(this.translateService.instant(data.message, { value: this.securitySettings.enforcePwdHistoryCount }), this.translateService.instant('Action.Error'), this.constantService.ToastError);
          } else {
            this.toastrService.error(this.translateService.instant(data.message), this.translateService.instant('Action.Error'), this.constantService.ToastError);
            
          }
          this.isSaving = false;
        } else {
          sessionStorage.setItem('dataconf', '');

          if (!this.authService.$enforceData._value) {
            await this.sharedService.getUserDetail();
          }
          this.authService.$enforceData._value.isMustChangePassword = !data.status;
          this.toastrService.success(this.translateService.instant(data.message), this.translateService.instant('Action.Success'));
          this.authService.redirectEnforceView(this.authService.$enforceData._value, false);
        }
      }));
  }

  logout() {
    this.authService.signOut();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  onEnterPress(){
    this.changePasswordForm.controls.confirmNewPassword.markAsTouched()
  }

}
