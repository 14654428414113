import { Directive, ElementRef, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { User } from 'oidc-client';
import { AuthService } from 'src/app/authentication/auth.service';
import { UserRoles } from './constant.service';
import { HasPermissionService } from './has-permission.service';


@Directive({
  selector: '[hasPermission]'
})
export class HasPermissionDirective implements OnInit {
  private currentUser: User;
  private roles = [];

  constructor(private templateRef: TemplateRef<any>,private viewContainer: ViewContainerRef, private permissions: HasPermissionService) 
  {
  }

  async ngOnInit() {
    this.updateView();
  }

  @Input()
  set hasPermission(val: UserRoles[]) {
    this.roles = val;
    this.updateView();
  }

  private updateView() {
    this.viewContainer.clear();
    if (this.permissions.allowAccess(this.roles)) {
        this.viewContainer.createEmbeddedView(this.templateRef);
    }
  }

}

