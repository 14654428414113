import { Component, OnInit } from '@angular/core';
import { OrganizationDirectoryModel, OrganizationDirectoryService,RadiusClientService,AgentService } from 'src/app/services/organization';
import { DirectoryService } from 'src/app/services/master';
import { AuthService } from '../../authentication/auth.service';
import { HasPermissionService } from '../../services/common/has-permission.service';
import { UserRoles } from '../../services/common/constant.service';
import { ConstantService } from '../../services/common/constant.service';

@Component({
  selector: 'app-directory-details',
  templateUrl: './directory-details.component.html',
  styleUrls: ['./directory-details.component.scss']
})
export class DirectoryDetailsComponent implements OnInit {
  directories: OrganizationDirectoryModel[];
  private orgId: number;
  agents: any[];

  constructor(
    private directoryService :DirectoryService,
    private authService: AuthService,
    private permision : HasPermissionService,
    private organizationDirectoryService : OrganizationDirectoryService,
    private radiusClientService: RadiusClientService,
    private agentService: AgentService,
    private constantService: ConstantService
  ) { }

  ngOnInit(): void {
    this.orgId = this.authService.organizationId;
    if (this.permision.allowAccess([UserRoles.AdminUser, UserRoles.MspAdmin, UserRoles.SuperAdmin,])){
      this.organizationDirectoryService.apiOrganizationDirectoryGetByOrgIdOrganizationIdGet(this.orgId).subscribe({
        next: (data) => {
          this.directories = data;
          this.directories.sort((a, b)=> a.directoryName.localeCompare(b.directoryName));
        },
        complete: () => {}
      })

      this.agentService.apiAgentByOrgKeyOrganizationMnemonicGet(this.constantService.getSubdomain()).subscribe(data => {
        data.forEach(agent => {
          agent.radiusClients = JSON.parse(agent.radiusClientID);
          agent.directoriesClients = JSON.parse(agent.organizationDirectoryID);
        });
  
        this.agents = data;

        this.radiusClientService.apiRadiusClientByorgidOrganizationIdGet(this.orgId).subscribe({
          next: (data) => {
            data.forEach(data => {
              const agent = this.agents.find(x => x.radiusClients?.includes(data.radiusClientId));
              const status = !agent?.name ? "": "Connected";
              const radiusClient: OrganizationDirectoryModel = {
                directoryName: "Radius Agent",
                directoryLabel: data.friendlyName,
                status: status,
                directoryId: data.radiusClientId,
                organizationId: data.organizationId,
                lastSync: agent?.lastConnectionDate
              };
              this.directories.push(radiusClient);
            });
            this.directories.sort((a, b) => a.directoryName.localeCompare(b.directoryName));
          },
          complete: () => {
          }
        });
        });

      
      
    }
  }
  

}
