import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-usernameless-modal',
  templateUrl: './usernameless-modal.component.html',
  styleUrls: ['./usernameless-modal.component.scss']
})
export class UsernamelessModalComponent implements OnInit {

  constructor(
    private modal: NgbActiveModal
  ) { }

  ngOnInit(): void {
  }

  close(result: boolean): void {
    this.modal.close(result);
  }

}
