import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ConstantService, ThemeEnum } from '../../services/common/constant.service';

@Component({
  selector: 'app-register-welcome',
  templateUrl: './register-welcome.component.html',
  styleUrls: ['./register-welcome.component.scss']
})
export class RegisterWelcomeComponent implements OnInit, OnDestroy {

  subscriptions: Subscription = new Subscription;

  url: string;
  logoImage: string;
  signupBackground: string;

  constructor(
    public constantService: ConstantService,
    private activatedRoute: ActivatedRoute
  )
  {

  }   

  ngOnInit(): void {
    const logoImage = this.constantService.getSessionStorage(ThemeEnum.signinLogo);
    this.logoImage = logoImage ?? "assets/images/logo.png";

    const signupBg = this.constantService.getSessionStorage(ThemeEnum.signupBackground);
    this.signupBackground = signupBg ?? "assets/images/register-background.png";

    this.subscriptions.add(this.activatedRoute.queryParams.subscribe(params => {
      this.url = params['url']
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  login() {
    window.location.href = this.url ? 'https://' + this.url : '/';
  }

  onNavigation() {
    window.location.href = this.url ? 'https://' + this.url : '/';
  }

}
