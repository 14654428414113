/**
 * Organization Microservice
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type SmsAllowancePeriod = 'None' | 'Weekly' | 'Monthly' | 'Yearly';

export const SmsAllowancePeriod = {
    None: 'None' as SmsAllowancePeriod,
    Weekly: 'Weekly' as SmsAllowancePeriod,
    Monthly: 'Monthly' as SmsAllowancePeriod,
    Yearly: 'Yearly' as SmsAllowancePeriod
};

