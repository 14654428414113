import { Injectable } from '@angular/core';
import { AppSettings } from './appsettings.model';

@Injectable()
export class AppSettingsService {
    public settings = new AppSettings("3.0 beta 1", false, "en", false, true, '');

    _selectedGroup = null;

    set selectedGroup(group: any) {
        localStorage.setItem('selectedGroup', JSON.stringify(group));
        this._selectedGroup = group;
    }

    get selectedGroup(): any {
        return this._selectedGroup;
    }

    constructor() {
        this.selectedGroup = JSON.parse(localStorage.getItem('selectedGroup')) || null;
    }

}