import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  lineChartData = [{
    data: [12, 15, 18, 40, 35, 38, 32, 20, 25, 15, 25, 30],
    borderWidth: 1,
    fill: true
  }];

  lineChartColors = [
    {
      borderColor: 'rgba(0,123,255,1)',
      backgroundColor: 'rgba(0,123,255,.2)'
    }
  ];

  lineChartLabels = ['Oct 1', 'Oct 2', 'Oct 3', 'Oct 4', 'Oct 5', 'Oct 6', 'Oct 7', 'Oct 8', 'Oct 9', 'Oct 10', 'Oct 11', 'Oct 12'];

  lineChartOptions = {
    maintainAspectRatio: false,
    legend: {
      display: false,
        labels: {
          display: false
        }
    },
    scales: {
      yAxes: [{
        display: false,
        ticks: {
          beginAtZero:true,
          fontSize: 10,
          max: 80
        }
      }],
      xAxes: [{
        ticks: {
          beginAtZero:true,
          fontSize: 11,
          fontFamily: 'Arial'
        }
      }]
    }
  };

}
