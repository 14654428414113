import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { ConstantService } from '../../../services/common/constant.service';
import { QuestionSecretAnswerService, UserQuestionSecretAnswerModel } from '../../../services/identity';
import { OrganizationQuestionSecretModelBinding, OrganizationQuestionSecretService, OrganizationQuestionsModelBinding } from '../../../services/organization';
import { AuthService } from '../../auth.service';
import { SharedAuthenticationService } from '../../shared/shared-authentication.service';

@Component({
  selector: 'app-security-questions-configure',
  templateUrl: './security-questions-configure.component.html',
  styleUrls: ['./security-questions-configure.component.scss']
})
export class SecurityQuestionsConfigureComponent implements OnInit, OnDestroy {

  subscriptions: Subscription = new Subscription;
  questionsForm: FormGroup;
  availableQuestions: OrganizationQuestionSecretModelBinding[][] = [];

  orgId: number;
  orgMnemonic: string;
  username: string;

  orgSettings: OrganizationQuestionsModelBinding

  answers: UserQuestionSecretAnswerModel[] = [];

  isSaving: boolean;

  constructor(
    private authService: AuthService,
    private constantService: ConstantService,
    private orgQuestionsService: OrganizationQuestionSecretService,
    private secretAnswerService: QuestionSecretAnswerService,
    private toastService: ToastrService,
    private sharedService: SharedAuthenticationService
  ) { }


  ngOnInit(): void {
    this.orgId = this.authService.organizationId;
    this.orgMnemonic = this.constantService.getSubdomain(false);
    this.username = this.authService.userName;

    this.createForm();

    this.getOrganizationQuestionsWithSettings();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  createForm(): void {
    this.questionsForm = new FormGroup({
      questions: new FormArray([]),
    });
  }

  get questionControls(): FormArray { return this.questionsForm.controls.questions['controls']; }

  getOrganizationQuestionsWithSettings(): void {
    this.orgQuestionsService.apiOrganizationQuestionSecretGetOrganizationQuestionWithSettingsOrganizationIdGet(this.orgId).subscribe(
      (data) => {
        this.orgSettings = data;
        this.getUserAnswers();
      });
  }

  getUserAnswers() {
    this.subscriptions.add(this.secretAnswerService.apiQuestionSecretAnswerGetUserAnswersGet(this.orgMnemonic, this.username).subscribe(
      (data) => {
        const noOfQuestions = this.orgSettings.noOfConfigurableQuestionSecret;
        if (data?.length > 0) {
          data.forEach((questionData) => {
            this.addQuestion(questionData);
          });
          if (noOfQuestions > data.length) {
            const remainingQuestions = noOfQuestions - data.length
            this.addRequiredQuestions(remainingQuestions);
          }
        } else {
          this.addRequiredQuestions(noOfQuestions);
        }
        this.setAvailableQuestions();
      }
    ));
  }

  addRequiredQuestions(requiredQuestions: number) {
    for (let index = 0; index < requiredQuestions; index++) {
      this.addQuestion();
    }
  }

  addQuestion(answer?: any): void {
    let questions = this.questionsForm.get('questions') as FormArray;
    questions.push(new FormGroup({
      questionId: new FormControl(answer?.organizationQuestionSecretId ?? null, [Validators.required]),
      answer: new FormControl(answer?.answer ?? null, [Validators.required, Validators.minLength(3)])
    }));
  }

  setAvailableQuestions(): void {
    const selectedQuestions = this.questionsForm.value.questions.map(x => x.questionId);
    for (let i = 0; i < this.orgSettings.noOfConfigurableQuestionSecret; i++) {
      const thisQuestion = selectedQuestions[i];
      this.availableQuestions[i] = this.orgSettings.organizationQuestions.filter(x => x.organizationQuestionSecretId == thisQuestion || selectedQuestions.indexOf(x.organizationQuestionSecretId) == -1);
    }
  }


  save() {
    this.isSaving = true;
    if (this.questionsForm.invalid) {
      return;
    }
    this.questionsForm.value.questions.forEach(x => {
      this.answers.push({ organizationQuestionSecretId: x.questionId, answer: x.answer });
    })
    this.secretAnswerService
      .apiQuestionSecretAnswerSaveUserQuestionSecretAnswerOrganizationMnemonicUsernamePost(
        this.orgMnemonic, this.username, this.answers
      ).subscribe({
        next: async (data) => {
          if (data > 0) {
            sessionStorage.setItem('dataconf', '');

            if (!this.authService.$enforceData._value) {
              await this.sharedService.getUserDetail();
            }
            this.authService.$enforceData._value.questionSecretStatus = '';
            this.toastService.success('Security Questions saved', 'Success');
            setTimeout(() => {
              this.authService.redirectEnforceView(this.authService.$enforceData._value, false)
            }, 1000);
          }
          else {
            this.toastService.info('Security Question not saved', '');
          }
        },
        error: () => {
          this.isSaving = false;
        }
      });
  }

}
