import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: 'confirm-dialog.component.html',
  styleUrls: ['confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent {
  @Input() title = this.translateService.instant('Modal.Confirm.Title');
  @Input() message = this.translateService.instant('Modal.Confirm.Message');

  // 'default' or 'danger'
  @Input() type = 'default';

  constructor(
    private activeModal: NgbActiveModal,
    private translateService: TranslateService
  ) {}
}
