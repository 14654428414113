import { Component, OnDestroy, OnInit, Input } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { MyAppGroupApplicationService } from 'src/app/services/user';

@Component({
  selector: 'app-add-application-to-group-modal',
  templateUrl: './add-application-to-group-modal.component.html',
  styleUrls: ['./add-application-to-group-modal.component.scss']
})
export class AddApplicationToGroupModalComponent implements OnInit {
  public addMyAppGroupApplicationsForm: FormGroup
  @Input() userId;
  @Input() organizationId;
  @Input() applicationList;
  @Input() groupList;

  constructor(
    private fb: FormBuilder,
    private toastrService: ToastrService,
    private translateService: TranslateService,
    public activeModal: NgbActiveModal,
    public myAppGroupApplicationService: MyAppGroupApplicationService
  ) { }

  ngOnInit(): void {
    this.initForm();
  }
  private initForm() {
    this.addMyAppGroupApplicationsForm = this.fb.group({
      myAppGroupId: [Validators.compose([Validators.required])],
    });
  }

  save() {
    if (!this.addMyAppGroupApplicationsForm.controls.myAppGroupId.errors?.required) {
      var myAppGroupId = this.addMyAppGroupApplicationsForm.value.myAppGroupId;
      this.myAppGroupApplicationService.apiMyAppGroupApplicationAddMyAppGroupApplicationsUserIdOrganizationIdMyAppGroupIdPost(this.userId, this.organizationId, myAppGroupId, this.applicationList).subscribe(data => {
        this.toastrService.success(this.translateService.instant('Action.Success'));
        this.activeModal.close(true);
      })
    }

  }

}
