/**
 * Organization Microservice
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type AuthenticationType = 'Platform' | 'Passcode' | 'SkipPassword' | 'ChallengeNotSupport';

export const AuthenticationType = {
    Platform: 'Platform' as AuthenticationType,
    Passcode: 'Passcode' as AuthenticationType,
    SkipPassword: 'SkipPassword' as AuthenticationType,
    ChallengeNotSupport: 'ChallengeNotSupport' as AuthenticationType
};

