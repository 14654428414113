/**
 * Organization Microservice
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface SecuritySettingModel { 
    securitySettingId?: number;
    passwordMinLength?: number;
    pwdMustIncludeLowercase?: boolean;
    pwdMustIncludeUppercase?: boolean;
    pwdMustIncludeNumber?: boolean;
    pwdMustIncludeSpecialCharacter?: boolean;
    pwdMayNotContainUserName?: boolean;
    enableEnforcePwdHistory?: boolean;
    enforcePwdHistoryCount?: number;
    enableLockoutonFailedLoginAttempts?: boolean;
    organizationId: number;
    mnemonic: string;
    isActive?: boolean;
    modifiedBy?: string | null;
    passwordExpiryDays?: number;
}

