/**
 * Identity Server Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type RadProtocol = 'PAP' | 'CHAP' | 'MSCHAP' | 'MSCHAP2' | 'EAP';

export const RadProtocol = {
    Pap: 'PAP' as RadProtocol,
    Chap: 'CHAP' as RadProtocol,
    Mschap: 'MSCHAP' as RadProtocol,
    Mschap2: 'MSCHAP2' as RadProtocol,
    Eap: 'EAP' as RadProtocol
};

