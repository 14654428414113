import { Component, OnInit, OnDestroy} from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { AuthService } from '../authentication/auth.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { ReleaseNotesService } from '../services/master';
import { Subscription } from 'rxjs';
import { DatePipe } from '@angular/common'

@Component({
  selector: 'app-whats-new',
  templateUrl: './whats-new.component.html',
  styleUrls: ['./whats-new.component.scss']
})
export class WhatsNewComponent implements OnInit {
  public releaseNotes;
  private subscriptions: Subscription[] = [];

  constructor(
  private translate: TranslateService,
  private releseNoteService: ReleaseNotesService,
  private datepipe: DatePipe
  ) { }

  ngOnInit(): void {
    this.getAllReleaseNotes()
  }

  getAllReleaseNotes(){
    this.releaseNotes = [];
    this.releseNoteService.apiReleaseNotesGetReleaseNotesGet().subscribe((data) => {
      this.releaseNotes = data.map(item => {
        return {
          ...item,
          releaseNote: this.parseReleaseNotes(item.releaseNote).ReleaseNotes,
          initialDate: this.parseDate(item.initialDate)

        }
      }
      );
    });    

  }
  parseDate(date){
    try {
      return this.datepipe.transform(new Date(date), 'd MMM y');;
    }
    catch(e){
      return "";
    }

  }

  parseReleaseNotes(jsonString){
    try {
      return JSON.parse(jsonString);
    }
    catch(e){
      return {releaseNotes:[]};
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

}
