import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavigationStart, Router } from '@angular/router';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { interval, Observable, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from './authentication/auth.service';
import { SharedAuthenticationService } from './authentication/shared/shared-authentication.service';
import { AppSettingsService } from './services/common/appsettings.service';
import { ConstantService } from './services/common/constant.service';
import { UserSessionService } from './services/identity';
import { ThemeModel, ThemeService } from './services/organization';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  showRouter: boolean = false;
  showHeader: boolean = true;
  showFooter: boolean = true;
  loadingLang: boolean = true;
  themeModel: ThemeModel = {};

  exactRoutesWithoutPageDecorations: string[] = [
    '/', '/login', '/signin', '/auth-error', '/auth/register'
  ]

  startsWithRoutesWithoutPageDecorations: string[] = [
    '/auth/logout', '/auth-callback', '/auth/register-welcome', '/auth/change-password',
    '/verify-user', '/enforce-push', '/enforce-fido', '/cloud/azure/custom-redirection', '/enforce-enrollment', '/styled-message',
    '/organization/licence/update',
  ]

  private activeTimer: Subscription;

  constructor(private router: Router,
    private translate: TranslateService,
    public authService: AuthService,
    private title: Title,
    private themeService: ThemeService,
    private sharedService: SharedAuthenticationService,
    private constantService: ConstantService,
    public appSettings: AppSettingsService,
    private cookieService: CookieService,
    private userSessionService: UserSessionService,
    private idle: Idle) {

    // Removing Sidebar, Navbar, Footer for Documentation, Error and Auth pages
    router.events.forEach((event: any) => {


      if (event instanceof NavigationStart) {

        if (this.exactRoutesWithoutPageDecorations.indexOf(event['url']) != -1) {
          this.showHeader = false;
          this.showFooter = false;

        } else if (this.startsWithRoutesWithoutPageDecorations.some(x => event['url'].startsWith(x))) {
          this.showHeader = false;
          this.showFooter = false;
        }
        else {
          const data = sessionStorage.getItem('dataconf') ;
          if (data!= '' && data != null && data != undefined) {
            return this.router?.navigate(JSON.parse(data));
          } else {
            this.showHeader = true;
            this.showFooter = true;

          }
        }


        if (window.matchMedia('(max-width: 991px)').matches) {
          document.querySelector('body')!.classList.remove('az-sidebar-show');
        }
      }
    });


  }
  ngOnDestroy(): void {
    this.activeTimer.unsubscribe();
  }

  async ngOnInit() {

    this.configureIdle();

    // handle admin toggling for reload behavior
    if (this.cookieService.check('loadStatus')) {
      this.appSettings.settings.adminMenu = true;
      this.cookieService.delete('loadStatus');
    }
    window.addEventListener('beforeunload', () => {
      if (this.appSettings.settings.adminMenu) {
        const expires = new Date();
        expires.setSeconds(expires.getSeconds() + 5);
        this.cookieService.set('loadStatus', 'true', expires);
      }
    });
    // end handle admin toggling for reload behavior

    await this.getLocalSettings();
    this.title.setTitle(this.constantService.showOrgName);
    this.getTheme();
    this.checkUri();
    ;
    this.activeTimer = interval(60 * 1000).subscribe(() => {
      if (!this.idle.isIdling()) {
        this.updateActive();
      }
    })

  }

  checkUri(): void {
    if (environment.production) {
      let publicUrl = environment.publicUrl;
      if (publicUrl.startsWith('.')) {
        publicUrl = publicUrl.substring(1);
      }
      if (window.location.hostname.indexOf(publicUrl) == -1) {
        const orgName = this.constantService.getSubdomain();
        window.location.hostname = `${orgName}.${publicUrl}`;
      }
    }
  }

  private async getLocalSettings() {

    await this.authService.loadLanguages();

    if (window.location.pathname == '/') {
      this.authService.redirectUser();
    }
    // navbar backdrop for mobile only
    const navbarBackdrop = document.createElement('div');
    navbarBackdrop.classList.add('az-navbar-backdrop');
    document.querySelector('body')!.appendChild(navbarBackdrop);
    navbarBackdrop.addEventListener('click', () => {
      document.querySelector('body')!.classList.toggle('az-sidebar-show');
    });

    this.loadingLang = false;
  }

  private getTheme() {
    this.themeService.apiThemeThemeMnemonicGet(this.constantService.getSubdomain(false)).subscribe({
      next: (result) => {
        let obj;
        //obj = {
        //  "theme-color-primary": result?.['primaryColor'] ?? '#262C68',
        //  "theme-color-secondary": result?.['secondaryColor'] ?? '#17C8E5'
        //}
        obj = {
          "theme-color-primary": '#262C68',
          "theme-color-secondary": '#17C8E5'
        }
        this.setTheme(obj);

        if (result?.isTheme) {
          this.setImage(result);
          this.constantService.setSessionStorage(result);
          this.setFavIcon(result.favicon);
          this.constantService.showOrgName = result['organizationName'] ?? "SecurEnvoy";
        }
        this.showRouter = true;
      },
      error: (err) => {
        console.log('===> theme error', err);
        this.showRouter = true;
        this.router.navigateByUrl('/error');
      },
      complete: () => {
      }
    });
  }

  private setTheme(theme: {}) {
    Object.keys(theme).forEach(k =>
      document.documentElement.style.setProperty(`--${k}`, theme[k])
    );
  }

  private setImage(data) {

    for (const property in this.themeModel) {
      if (data[property]) {
        data[property] = environment.filestore + '/Filestore/GetImageByID/' + data[property];
      }
    }
  }

  setFavIcon(data) {
    if (data) {
      document.getElementById("favIcon").setAttribute("href", environment.filestore + '/Filestore/GetImageByID/' + data);
    }
  }

  private configureIdle() {
    this.idle.setIdle(5 * 60);
    this.idle.setTimeout(8 * 60 * 60);
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this.idle.watch();
    this.idle.onIdleStart.subscribe(() => {

    });

    this.idle.onIdleEnd.subscribe(() => {
      this.updateActive();
    });

    this.idle.onTimeout.subscribe(() => {
      this.authService.signOut();
    });

  }

  private updateActive() {
    const orgId = this.authService.user?.profile.organizationId ?? this.authService.user?.profile.OrganizationId;
    if (this.authService.sessionId && orgId) {
      this.userSessionService.apiUserSessionUpdateLastActiveOrganizationIdUserIdSessionIdPut(orgId, Number(this.authService.id), this.authService.sessionId).subscribe(data => {
      });
    }
  }
}
