/**
 * Organization Microservice
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface AttributeMappingModel { 
    attributeMappingId?: number;
    sourceOrganizationDirectoryId: number;
    sourceColumnName?: string | null;
    isSourceCustomAttribute?: boolean;
    destinationColumnName?: string | null;
    mappingType?: string | null;
    udTableName?: string | null;
    isReadonly?: boolean;
    displayName?: string | null;
    attributeMetadataId?: number;
    expressionId?: number;
    organizationDirectoryId: number;
    createdBy?: string | null;
    modifiedBy?: string | null;
    modifiedDate?: string;
    isActive?: boolean;
    isTypeReadOnly?: boolean;
}

