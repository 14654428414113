import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UsersService } from 'src/app/services/user';
import { Subject, takeUntil } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/authentication/auth.service';
import { FormControl, Validators } from '@angular/forms';

@Component({
    selector: 'app-admin-validation-modal',
    templateUrl: 'admin-validation-modal.html',
})


export class AdminValidationModal implements OnInit, OnDestroy {

    destroy$: Subject<boolean> = new Subject<boolean>();

    isSent:boolean = false;
    userInputCode: FormControl;
    userInputHasError: boolean = true;
    cell:string = "";
    email:string = "";

    //add loading animation to advoid user sending multiple email/sms
    isEmailLoading:boolean = false;
    isSMSLoading:boolean = false;
    isVerifyLoading:boolean = false; 

    constructor(
      private activeModal: NgbActiveModal,
      private userService: UsersService,
      private toastrService: ToastrService,
      private authService: AuthService
    ) {}

    ngOnInit() {
      this.userService.apiUsersGetUserBasicInfoGet().subscribe(
        data => {
          if(data.cell) this.cell = data.cell;
          if(data.email) this.email = data.email;
        }
      );
      this.userInputCode = new FormControl('', Validators.required);
      this.userInputCode.valueChanges.pipe(
        takeUntil(this.destroy$)
      ).subscribe(val=>{
        if(val && val.length != 6){
          this.userInputCode.setErrors({'inValid': true});
          this.userInputHasError = true;
        }else{
          this.userInputCode.setErrors(null);
          this.userInputHasError = false;
        }
      });
    }

    sendValidationEmail() {
      this.isEmailLoading = true;
      this.userService.apiUsersSendAdminValidationCodeEmailOrganizationIdUserIdPut(this.authService.user.profile.OrganizationId,  this.authService.user.profile.UserID).pipe(
          takeUntil(this.destroy$)
        ).subscribe({
          next: (result) => {},
        error: () => {},
        complete: () => { 
          this.isSent = true;
          this.isEmailLoading = false;
          this.toastrService.success('Validation Email Sent');
        }
      });
    }

    sendValidationSMS() {
      this.isSMSLoading = true;
      this.userService.apiUsersSendAdminValidationCodeSMSOrganizationIdUserIdPut(this.authService.user.profile.OrganizationId,  this.authService.user.profile.UserID).pipe(
        takeUntil(this.destroy$)
      ).subscribe({
        next: (result) => {},
        error: () => {},
        complete: () => { 
          this.isSent = true;
          this.isSMSLoading = false;
          this.toastrService.success('Validation SMS Sent');
        }
      });
    }

    verify(){
      if(this.userInputHasError){ return }
      this.isVerifyLoading = true;
      this.userService.apiUsersVerifyAdminValidationCodeOrganizationIdUserIdPut(this.authService.user.profile.OrganizationId,  this.authService.user.profile.UserID, `"${this.userInputCode.value}"`).pipe(
        takeUntil(this.destroy$)
      ).subscribe({
        next: (result) => {
          if(result){
            this.toastrService.success('Validation Successful'); 
            setTimeout(() => {
              this.closeModal(true);
            }, 1000);
          }else{
            this.toastrService.error('Validation Failed');
            this.userInputCode.reset();
            this.isVerifyLoading = false;
            this.userInputHasError = true;
          }
        },
        error: () => {},
        complete: () => {}
      });
    }

    cancel(){
      this.userInputCode.reset();
      this.isSent = false;
    }

    closeModal(isValida: boolean){
      this.activeModal.close(isValida);
    }

    ngOnDestroy(){
      this.destroy$.next(true);
      this.destroy$.unsubscribe();
    }
}