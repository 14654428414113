import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { ConstantService } from '../../../services/common/constant.service';
import { QuestionSecretAnswerService, UserQuestionSecretAnswerModel } from '../../../services/identity';
import { AuthService } from '../../auth.service';
import { SharedAuthenticationService } from '../../shared/shared-authentication.service';

@Component({
  selector: 'app-security-questions-answer',
  templateUrl: './security-questions-answer.component.html',
  styleUrls: ['./security-questions-answer.component.scss']
})
export class SecurityQuestionsAnswerComponent implements OnInit, OnDestroy {


  subscriptions: Subscription = new Subscription;
  isSaving: boolean;

  orgMnemonic: string;
  username: string;

  questionsForm: FormGroup;
  answers: UserQuestionSecretAnswerModel[] = [];

  constructor(
    private constantService: ConstantService,
    private authService: AuthService,
    private secretAnswerService: QuestionSecretAnswerService,
    private sharedService: SharedAuthenticationService,
    private toastService: ToastrService
  ) { }


  ngOnInit(): void {
    this.orgMnemonic = this.constantService.getSubdomain(false);
    this.username = this.authService.userName;

    this.createForm();

    this.getUserQuestions();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  createForm(): void {
    this.questionsForm = new FormGroup({
      questions: new FormArray([]),
    });
  }

  get questionControls(): FormArray { return this.questionsForm.controls.questions['controls']; }

  getUserQuestions() {
    this.subscriptions.add(this.secretAnswerService.apiQuestionSecretAnswerGetUserQuestionsGet(this.orgMnemonic, this.username).subscribe(data => {
      data.forEach(questionData => {
        this.addQuestion(questionData);
      })
    }))
  }

addQuestion(question: any): void {
  let questions = this.questionsForm.get('questions') as FormArray;
  questions.push(new FormGroup({
    questionId: new FormControl(question?.organizationQuestionSecretId),
    question: new FormControl(question?.question ?? null),
    answer: new FormControl(null, [Validators.required, Validators.minLength(3)])
  }));
}

  save() {
    this.isSaving = true;
    if (this.questionsForm.invalid) {
      return;
    }
    this.questionsForm.value.questions.forEach(x => {
      this.answers.push({ organizationQuestionSecretId: x.questionId, answer: x.answer });
    })
    this.secretAnswerService
      .apiQuestionSecretAnswerVerifyQuestionSecretOrganizationMnemonicUsernamePost(
        this.orgMnemonic, this.username, this.answers
      ).subscribe({
        next: async (data) => {
          if (data.isValid) {
            sessionStorage.setItem('dataconf', '');

            if (!this.authService.$enforceData._value) {
              await this.sharedService.getUserDetail();
            }
            this.authService.$enforceData._value.questionSecretStatus = '';
            setTimeout(() => {
              this.authService.redirectEnforceView(this.authService.$enforceData._value, false)
            }, 1000);
          }
          else {
            if (data.remainingAttempts <= 0) {
              this.toastService.error(`Your account has been locked temporarily. Please contact system administrator`, '', this.constantService.ToastError);
              this.sharedService.delayedSignout();
            }
            else {
              this.toastService.error(`Invalid answer(s), You have only ${data.remainingAttempts} attempt left.`, '', this.constantService.ToastError);
              this.isSaving = false;
            }
          }
        },
        error: () => {
          this.isSaving = false;
        }
      });
  }

}
