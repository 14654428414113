/**
 * Organization Microservice
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type LicenceType = 'Trial' | 'Normal' | 'Msp';

export const LicenceType = {
    Trial: 'Trial' as LicenceType,
    Normal: 'Normal' as LicenceType,
    Msp: 'Msp' as LicenceType
};

