import { Component, OnInit } from '@angular/core';
import { OrganizationDirectoryModel, OrganizationDirectoryService } from 'src/app/services/organization';
import { AuthService } from '../authentication/auth.service';
import { HasPermissionService } from '../services/common/has-permission.service';


@Component({
  selector: 'app-system-status',
  templateUrl: './system-status.component.html',
  styleUrls: ['./system-status.component.scss']
})
export class SystemStatusComponent implements OnInit {
  public activeTab: string;

  constructor(
    private authService: AuthService,
    private permision : HasPermissionService

  ) { 
    this.activeTab = 'system';
  }

  setActiveTab(tab: string) {
    this.activeTab = tab;
  }

  ngOnInit() {
  };
  


}
