import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';

@Component({
    selector: 'app-emali-compromised-modal',
    templateUrl: 'email-compromised-modal.html',
})

export class EmailCompromisedModal{

    destroy$: Subject<boolean> = new Subject<boolean>();

    constructor(
      private activeModal: NgbActiveModal
    ) {}

    closeModal(isContinue:boolean){
      this.activeModal.close(isContinue);
    }
}