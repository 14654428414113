import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-second-fa-modal',
  templateUrl: './second-fa-modal.html',
  styleUrls: ['./second-fa-modal.scss']
})
export class SecondFaModal implements OnInit, OnDestroy {

  constructor(
    public activeModal: NgbActiveModal,
  ) { }

  ngOnInit() {
  }

  ngOnDestroy() {
  }

  cancel() {
    this.activeModal.close('no');
  }

  allow() {
    this.activeModal.close('yes');
  }
}
