/**
 * Organization Microservice
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface AttributeMetadataModel { 
    attributeMetadataId?: number;
    attributeName: string;
    description?: string | null;
    displayName?: string | null;
    dataType?: string | null;
    minLength?: string | null;
    maxLength?: string | null;
    isMandatory?: boolean;
    isActive?: boolean;
    defaultValue?: string | null;
    attributeGroupKey?: string | null;
    organizationId?: number;
    mnemonic?: string | null;
    modifiedBy?: string | null;
    createdBy?: string | null;
    createdDate?: string;
    modifiedDate?: string;
}

