import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class FidoHelpersService {

  formatCredentialOptions(options: any): any {
    options.challenge = this.coerceToArrayBuffer(options.challenge);
    options.user.id = this.coerceToArrayBuffer(options.user.id);
    options.pubKeyCredParams = options.pubKeyCredParams.map((p) => {
      p.type = 'public-key';
      return p
    });
    options.excludeCredentials = options.excludeCredentials.map((c) => {
      c.id = this.coerceToArrayBuffer(c.id);
      return c;
    });
    if (options.authenticatorSelection.authenticatorAttachment === null) {
      options.authenticatorSelection.authenticatorAttachment = undefined;
    }

    if (!environment.production) {
      options.rp.id = 'localhost';
    }
    return options;
  }

  formatCredential(credential: any): any {
    let attestationObject = new Uint8Array(credential.response.attestationObject);
    let clientDataJSON = new Uint8Array(credential.response.clientDataJSON);
    let rawId = new Uint8Array(credential.rawId);

    const data = {
      id: credential.id,
      rawId: this.coerceToBase64Url(rawId),
      type: credential.type,
      extensions: credential.getClientExtensionResults(),
      response: {
        attestationObject: this.coerceToBase64Url(attestationObject),
        clientDataJSON: this.coerceToBase64Url(clientDataJSON)
      }
    };
    return data;
  }

  formatAssertionOptions(options: any): any {
    const challenge = options.challenge.replace(/-/g, '+').replace(/_/g, '/');
    options.challenge = Uint8Array.from(atob(challenge), c => c.charCodeAt(0));

    options.allowCredentials.forEach(function (c) {
      var fixedId = c.id.replace(/\_/g, '/').replace(/\-/g, '+');
      c.id = Uint8Array.from(atob(fixedId), c => c.charCodeAt(0));
    })

    return options;
  }

  coerceToArrayBuffer(thing: any): ArrayBuffer {
    if (typeof thing === "string") {
      // base64url to base64
      thing = thing.replace(/-/g, "+").replace(/_/g, "/");

      // base64 to Uint8Array
      var str = window.atob(thing);
      var bytes = new Uint8Array(str.length);
      for (var i = 0; i < str.length; i++) {
        bytes[i] = str.charCodeAt(i);
      }
      thing = bytes;
    }

    // Array to Uint8Array
    if (Array.isArray(thing)) {
      thing = new Uint8Array(thing);
    }

    // Uint8Array to ArrayBuffer
    if (thing instanceof Uint8Array) {
      thing = thing.buffer;
    }

    // error if none of the above worked
    if (!(thing instanceof ArrayBuffer)) {
      throw new TypeError("could not coerce to ArrayBuffer");
    }

    return thing;
  }

  coerceToBase64Url(thing: any): string {
    // Array or ArrayBuffer to Uint8Array
    if (Array.isArray(thing)) {
      thing = Uint8Array.from(thing);
    }

    if (thing instanceof ArrayBuffer) {
      thing = new Uint8Array(thing);
    }

    // Uint8Array to base64
    if (thing instanceof Uint8Array) {
      var str = "";
      var len = thing.byteLength;

      for (var i = 0; i < len; i++) {
        str += String.fromCharCode(thing[i]);
      }
      thing = window.btoa(str);
    }

    if (typeof thing !== "string") {
      throw new Error("could not coerce to string");
    }

    // base64 to base64url
    // NOTE: "=" at the end of challenge is optional, strip it off here
    thing = thing.replace(/\+/g, "-").replace(/\//g, "_").replace(/=*$/g, "");

    return thing;
  }

}
