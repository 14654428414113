import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CustomApplicationModel } from './application.model';

@Injectable({
  providedIn: 'root',
})
export class ApplicationStateService {
  applicationModel = {} as CustomApplicationModel;
  myApplicationState = { pageSize: 5, curPage: 1 };
  createdApplicationModel$: BehaviorSubject<CustomApplicationModel> =
    new BehaviorSubject({} as CustomApplicationModel);
  metadata$: BehaviorSubject<{}> = new BehaviorSubject({});
  constructor() {}
}
