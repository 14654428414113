/**
 * Organization Microservice
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface PassbackAttribute { 
    vendorId?: number;
    vendorCode?: number;
    attributeCode?: number;
    attributeType?: string | null;
    attributeName: string;
    donotSend?: boolean;
    sendPassword?: boolean;
    sendGroupsName?: boolean;
    prefix?: string | null;
    separator?: string | null;
    userDistinguishedName?: boolean;
    isSourceCustomAttribute?: boolean;
}

