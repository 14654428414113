import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChild, UrlTree } from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class ActivateAnonymous implements CanActivate, CanActivateChild {
    constructor(private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): true | UrlTree {
        return true;
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): true | UrlTree {
        return this.canActivate(route, state);
    }

}