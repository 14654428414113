import { Component, NgZone, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';



declare const gapi: any;

@Component({
})
export class GoogleAuthIframeComponent implements OnInit {


  clientId: string;
  clientSecret: string;
  discoveryDocs: string[];
  scopes: string = [
    "https://www.googleapis.com/auth/admin.directory.user",
    "https://www.googleapis.com/auth/admin.directory.group",
    "https://www.googleapis.com/auth/admin.directory.userschema",
    "https://www.googleapis.com/auth/admin.directory.user.readonly",
    "https://www.googleapis.com/auth/admin.directory.domain.readonly"
  ].join(" ");

  constructor(private zone: NgZone) {
    this.clientId = environment.googleDirectoryClientId;
    this.clientSecret = environment.googleDirectoryClientSecret;
  }

  ngOnInit(): void {
    this.loadClient().then(
      result => {
        return this.initClient()
      },
      err => { this.returnError(err) }
    ).then(result => {
      return this.signin();
    }, err => { this.returnError(err) })
  }

  loadClient(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.zone.run(() => {
        gapi.load('client:auth2', {
          callback: resolve,
          onerror: reject,
          timeout: 1000,
          ontimeout: reject
        });
      })
    })
  }

  initClient(): Promise<any> {
    let initObj = {
      apiKey: this.clientSecret,
      clientId: this.clientId,
      discoveryDocs: this.discoveryDocs,
      scope: this.scopes,
      plugin_name: 'workaround'
    }
    return new Promise((resolve, reject) => {
      this.zone.run(() => {
        gapi.client.init(initObj).then(resolve, reject);
      });
    })
  }



  /**
   *  Sign in the user upon button click.
   */
  signin(): Promise<any> {
    const authInstance = gapi.auth2.getAuthInstance();
    return new Promise((resolve, reject) => {
      this.zone.run(() => {
        authInstance.grantOfflineAccess()
          .then(result => {
            window.parent.window.postMessage({ "response": result }, document.referrer);
          },
            err => { this.returnError(err) }).then(resolve, reject);
      })
    })

  }

  returnError(err: any) {
    window.parent.window.postMessage({ "error": err }, document.referrer);
  }

}
