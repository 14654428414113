/**
 * User Microservice
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UserModel } from './userModel';
import { UserApplicationModel } from './userApplicationModel';
import { UsersGroups } from './usersGroups';


export interface UserAddModel { 
    userModel?: UserModel;
    usersGroups?: UsersGroups;
    userApplicationList?: Array<UserApplicationModel> | null;
}

