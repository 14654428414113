/**
 * Organization Microservice
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface OrganizationApplicationModel { 
    organizationApplicationId?: string;
    organizationId?: number;
    organizationMnemonic?: string | null;
    applicationName?: string | null;
    applicationLabel?: string | null;
    description?: string | null;
    createdBy?: string | null;
    createdDate?: string;
    modifiedBy?: string | null;
    modifiedDate?: string;
    isActive?: boolean;
    isDeleted?: boolean;
    isVerfied?: boolean;
    applicationMetadata?: string | null;
    applicationType?: string | null;
    logoUrl?: string | null;
    mapping?: string | null;
}

