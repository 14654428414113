import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpClient,
  HttpErrorResponse,
  HttpEvent,
  HttpResponse,
} from '@angular/common/http';
import { AuthService } from 'src/app/authentication/auth.service';
import { Router } from '@angular/router';
import { catchError, EMPTY, of, tap } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { ConstantService } from './constant.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  private knownErrors: string[] = [
    'Max limit reached for user creation against your organization.'
  ]

  constructor(
    private auth: AuthService,
    private router: Router,
    private toastrService: ToastrService,
    private constantService: ConstantService,
    private translateService: TranslateService,
    private httpClient: HttpClient
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    // Get the auth token from the service.
    const authToken: string = this.auth.authorizationHeaderValue;

    if (this.auth?.isLoginExpired) {
      this.auth.signOut();
    }

    let authReq = req.clone({
      url: req.url
        .replace('{org_code}', this.constantService.getSubdomain(false))
        .replace(RegExp('^(?:.*?/api/){2}'), function (x) {
          return x.replace(RegExp('/api/' + '$'), '/');
        }),
    });

    if (authToken) {
      authReq = authReq.clone({
        headers: authReq.headers.set('Authorization', authToken),
      });
    }

    // send cloned request with header to the next handler.
    return next.handle(authReq).pipe(
      tap({
        next: (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            //console.log('hide waiting spinners');
          }
        },
        error: (err) => {
          if (err instanceof HttpErrorResponse && !this.auth?.isLoginExpired) {
            console.log(err.status, err);
            if (req.url?.toLowerCase().includes('/getversion')) {
              return;
            }
            if (req.url?.toLowerCase().includes('/updatelastactive')) {
              return EMPTY;
            }
            if (req.url?.toLowerCase().includes('/licence/')) {
              return EMPTY;
            }
            if (err.error?.error instanceof SyntaxError && !err.error?.text.startsWith('<')) {
              this.toastrService.error(err.error.text, 'General Error', this.constantService.ToastError);
              return of();
            }
            switch (err.status) {
              case 401:
                if (authToken) {
                  this.toastrService.error(
                    'Unauthorized Error',
                    err.statusText,
                    this.constantService.ToastError
                  );
                } else {
                  this.router.navigate(['login'], {
                    state: { errorMessage: err.message },
                  });
                }
                break;

              case 403:
                //this.router.navigate(['error'], { state: { errorMessage: err.message } })
                this.toastrService.error(
                  'Forbidden Error',
                  err.statusText,
                  this.constantService.ToastError
                );
                break;
              case 500:               
                if (err.error && this.knownErrors.indexOf(err.error) >= 0) {
                  this.toastrService.error(
                    err.error,
                    err.statusText,
                    this.constantService.ToastError
                  );
                  return EMPTY;
                } else {
                  this.toastrService.error(
                    'Service Error',
                    err.statusText,
                    this.constantService.ToastError
                  );
                }
                break;
              case 400:
                this.toastrService.error(
                  'General Error',
                  err.statusText,
                  this.constantService.ToastError
                );
                break;
              case 409:
                this.toastrService.error(
                  this.translateService.instant(err.error),
                  this.translateService.instant('Action.Error'),
                  this.constantService.ToastError
                );
                break;
              default:
                //this.router.navigate(['error'], { state: { errorMessage: err.statusText } })
                this.toastrService.error(
                  'General Error',
                  err.statusText,
                  this.constantService.ToastError
                );
            }
          }
        },
      })
    );
  }
}
