/**
 * Identity Server Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AuthenticationTypeModelUserCheckEnrollCodeModel } from './authenticationTypeModelUserCheckEnrollCodeModel';


export interface AuthenticationTypeModel { 
    authenticationTypeId?: number;
    authenticationTypeName?: string | null;
    userName?: string | null;
    organizationMnemonic?: string | null;
    sequence?: number;
    isActive?: boolean;
    isDefault?: boolean;
    jsonData?: string | null;
    tokenCounter?: string | null;
    modifiedBy?: string | null;
    userCheckEnrollCodeModel?: AuthenticationTypeModelUserCheckEnrollCodeModel | null;
    pushEnabled?: boolean;
}

