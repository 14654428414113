/**
 * Identity Server Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface HardwareTokenModel { 
    id?: number;
    organizationId?: number;
    organizationMnemonic?: string | null;
    assignedTo?: string | null;
    type?: number;
    serialId?: string | null;
    seed?: string | null;
    isActive?: boolean | null;
    other?: string | null;
    createdBy?: string | null;
    createdOn?: string;
    modifiedBy?: string | null;
    modifiedOn?: string | null;
}

