/**
 * Organization Microservice
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Group } from './group';


export interface GroupDirectory { 
    groupDirectoryID?: number;
    groupID?: number;
    organizationDirectoryID?: number | null;
    organizationID?: number | null;
    directoryName?: string | null;
    uniqueID?: string | null;
    modifiedDate?: string;
    modifiedBy?: string | null;
    group?: Group;
}

