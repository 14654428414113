/**
 * Organization Microservice
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface AgentModel { 
    agentID?: string | null;
    mnemonic?: string | null;
    name?: string | null;
    lastConnectionDate?: string;
    connectionId?: string | null;
    lastSync?: string;
    organizationDirectoryID?: string | null;
    radiusClientID?: string | null;
    syslogClientID?: string | null;
    isActive?: boolean | null;
    version?: string | null;
    pendingUpdate?: boolean | null;
}

