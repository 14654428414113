/**
 * Conditional Access Microservice
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { QueryBuilderRuleInputModel } from './queryBuilderRuleInputModel';


export interface QueryBuilderInputModel { 
    organizationId?: string | null;
    id?: string | null;
    name?: string | null;
    isEnabled?: boolean;
    rules?: QueryBuilderRuleInputModel;
    result?: string | null;
    notificationAddresses?: Array<string> | null;
}

