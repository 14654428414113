import { DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ChartConfiguration } from 'chart.js';
import 'chartjs-adapter-date-fns';
import { BaseChartDirective } from 'ng2-charts';
import { Subscription } from 'rxjs';
import { AuthService } from '../authentication/auth.service';
import { AuthenticationsService, DashboardLoginsByApplicationViewModel, DashboardLoginsByAuthenticatorViewModel, DashboardLoginsViewModel } from '../services/analytics';
import { DashboardAuthenticatorEnrollmentViewModel } from '../services/analytics/model/dashboardAuthenticatorEnrollmentViewModel';
import { ConstantService } from '../services/common/constant.service';
import { CountryService } from '../services/master';
import { ApplicationSettingService } from '../services/organization';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {

  private authenticatorEnrollmentData = [];
  private authenticatorEnrollmentLabels = [];
  private authenticatorLoginsData = [];
  private authenticatorLoginsLabels = [];
  private applicationLoginsData = [];
  private applicationLoginsLabels = [];
  private failedLoginsData = [];
  startDate: Date;
  startDateDisplay: string;
  endDate: Date;
  endDateDisplay: string;
  totalUsers: number;
  configuredApplications: number;

  successfulLogins: number;
  successfulLoginsForPeriod: number;
  failedLogins: number;
  failedLoginsForPeriod: number;

  private colors = {
    lightBlue: '#00cccc',
    darkBlue: '#560bd0'
  }

  private strings = {
    authenticatorEnrollment: {
      enrolled: 'Enrolled',
      notEnrolled: 'Not Enrolled'
    },
    authenticatorLogins: {
      successful: 'Successful Logins',
      failed: 'Failed Logins'
    },
    applicationLogins: {
      successful: 'Successful Logins',
      failed: 'Failed Logins',
      otherApps: 'Other Apps'
    }
  }

  orgId: number;

  minBarLength: number = 2;

  private subscriptions = new Subscription;

  filterForm: FormGroup;

  datePipe: DatePipe;

  @ViewChildren(BaseChartDirective) charts: QueryList<BaseChartDirective>;

  constructor(
    private authService: AuthService,
    public countryService: CountryService,
    public authenticationsService: AuthenticationsService,
    public constantService: ConstantService,
    public translateService: TranslateService,
    private applicationSettingService: ApplicationSettingService  ) { }

  ngOnInit() {
    this.orgId = this.authService.organizationId;

    this.datePipe = new DatePipe(this.translateService.currentLang);

    this.subscriptions.add(this.translateService.onLangChange.subscribe(event => {
      this.updateTranslations();
    }))

    this.endDate = new Date();
    this.endDateDisplay = this.formatDate(this.endDate);
    this.startDate = new Date(new Date().setDate(this.endDate.getDate() - 30));
    this.startDate.setUTCHours(0, 0, 0, 0)
    this.startDateDisplay = this.formatDate(this.startDate);

    this.createFilterForm();

    this.getCharts();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  getCharts(): void {
    this.getTotalUsers();
    this.getConfiguredApplications();
    this.getAllLogins();

    this.subscriptions.add(
      this.authenticationsService.apiDashboardAuthenticationsEnrollmentOrganizationMnemonicGet(this.constantService.getSubdomain()).subscribe(result => {
        this.setAuthenticatorEnrollment(result);
      })
    );
    this.subscriptions.add(
      this.authenticationsService.apiDashboardAuthenticationsLoginsByAuthenticatorOrganizationMnemonicGet(this.constantService.getSubdomain(), this.startDate.toISOString(), this.endDate.toISOString()).subscribe(result => {
        this.setAuthenticatorLogins(result);
      })
    );
    this.subscriptions.add(
      this.authenticationsService.apiDashboardAuthenticationsLoginsByApplicationOrganizationMnemonicGet(this.constantService.getSubdomain(), this.startDate.toISOString(), this.endDate.toISOString()).subscribe(result => {
        this.setApplicationLogins(result);
      })
    )
    this.subscriptions.add(
      this.authenticationsService.apiDashboardAuthenticationsFailedLoginsOrganizationMnemonicGet(this.constantService.getSubdomain(), this.startDate.toISOString(), this.endDate.toISOString()).subscribe(result => {
        this.setFailedLogins(result);
      })
    )
    this.updateTranslations();
  }

  formatDate(date: Date) {
    return this.datePipe.transform(date, 'longDate', '+0');
  }

  ngbDate(date: Date): NgbDateStruct {
    return {year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate()}
  }

  createFilterForm(): void {
    this.filterForm = new FormGroup({
      startDate: new FormControl(this.ngbDate(this.startDate)),
      endDate: new FormControl(this.ngbDate(this.endDate))
    })
  }

  private updateTranslations() {

    this.strings.authenticatorEnrollment.enrolled = this.translateService.instant('Dashboard.AuthenticatorEnrollmentEnrolled');
    this.strings.authenticatorEnrollment.notEnrolled = this.translateService.instant('Dashboard.AuthenticatorEnrollmentNotEnrolled');
    if (this.authenticatorEnrollmentData[0]) {
      this.authenticatorEnrollmentData[0].label = this.strings.authenticatorEnrollment.enrolled;
      this.authenticatorEnrollmentData[1].label = this.strings.authenticatorEnrollment.notEnrolled;
    }

    this.strings.authenticatorLogins.successful = this.translateService.instant('Dashboard.AuthenticatorLoginsSuccessful');
    this.strings.authenticatorLogins.failed = this.translateService.instant('Dashboard.AuthenticatorLoginsFailed');
    if (this.authenticatorLoginsData[0]) {
      this.authenticatorLoginsData[0].label = this.strings.authenticatorLogins.successful;
      this.authenticatorLoginsData[1].label = this.strings.authenticatorLogins.failed;
    }

    this.strings.applicationLogins.successful = this.translateService.instant('Dashboard.ApplicationLoginsSuccessful');
    this.strings.applicationLogins.failed = this.translateService.instant('Dashboard.AuthenticatorLoginsFailed');
    this.strings.applicationLogins.otherApps = this.translateService.instant('Dashboard.OtherApps');
    if (this.authenticatorLoginsData[0]) {
      this.applicationLoginsData[0].label = this.strings.applicationLogins.successful;
      this.applicationLoginsData[1].label = this.strings.applicationLogins.failed;
    }

    if (this.charts) {
      this.charts.forEach(x => {
        x.chart.update();
      })
    }
  }

  changeStartDate(): void {
    const date = this.filterForm.value.startDate;
    this.startDate = new Date(Date.UTC(date.year, date.month - 1, date.day, 0, 0, 0));
    this.startDateDisplay = this.formatDate(this.startDate);
    this.getCharts();
  }

  changeEndDate(): void {
    const date = this.filterForm.value.endDate;
    this.endDate = new Date(Date.UTC(date.year, date.month - 1, date.day, 23, 59, 59, 999));
    this.endDateDisplay = this.formatDate(this.endDate);
    this.getCharts();
  }

  private setAuthenticatorEnrollment(data: DashboardAuthenticatorEnrollmentViewModel[]): void {
    const enabledAuthenticators = data.filter(x => x.isEnabled);
    this.authenticatorEnrollmentData = [
      {
        label: this.strings.authenticatorEnrollment.enrolled,
        data: enabledAuthenticators.map(x => x.enrolledCount),
        backgroundColor: this.colors.darkBlue
      },
      {
        label: this.strings.authenticatorEnrollment.notEnrolled,
        data: enabledAuthenticators.map(x => x.notEnrolledCount),
        backgroundColor: this.colors.lightBlue
      }
    ]
    this.authenticatorEnrollmentLabels = enabledAuthenticators.map(x => x.name)
  }

  authenticatorEnrollmentChartOptions: ChartConfiguration['options'] = {
    responsive: true,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
        ticks: {
          stepSize: 1
        }
      }
    }
  }

  private setAuthenticatorLogins(data: DashboardLoginsByAuthenticatorViewModel[]): void {
    this.authenticatorLoginsData = [
      {
        label: this.strings.authenticatorLogins.successful,
        data: data.map(x => x.successfulLoginCount),
        backgroundColor: this.colors.lightBlue,
        minBarLength: this.minBarLength
      },
      {
        label: this.strings.authenticatorLogins.failed,
        data: data.map(x => x.failedLoginCount),
        backgroundColor: this.colors.darkBlue,
        minBarLength: this.minBarLength
      }
    ]
    this.authenticatorLoginsLabels = data.map(x => x.name)
  }

  authenticatorLoginsOptions: ChartConfiguration['options'] = {
    scales: {
      y: {
        ticks: {
          precision: 0
        }
      }
    }
  }

  private setApplicationLogins(data: DashboardLoginsByApplicationViewModel[]): void {
    let apps = data;//.filter(x => x.successfulLoginCount > 0);
    if (apps.length > 10) {
      apps.sort((a, b) => a.successfulLoginCount - b.successfulLoginCount);
      var removed = apps.splice(10);
      const otherSuccessfulTotal = removed.reduce((a, b) => { return { successfulLoginCount: a.successfulLoginCount + b.successfulLoginCount } }).successfulLoginCount;
      const otherFailedTotal = removed.reduce((a, b) => { return { failedLoginCount: a.failedLoginCount + b.failedLoginCount } }).failedLoginCount;
      apps.push({ applicationLabel: this.strings.applicationLogins.otherApps, successfulLoginCount: otherSuccessfulTotal, failedLoginCount: otherFailedTotal });
    }
    this.applicationLoginsData = [
      {
        label: this.strings.applicationLogins.successful,
        data: apps.map(x => x.successfulLoginCount),
        backgroundColor: this.colors.lightBlue,
        minBarLength: this.minBarLength
      },
      {
        label: this.strings.applicationLogins.failed,
        data: apps.map(x => x.failedLoginCount),
        backgroundColor: this.colors.darkBlue,
        minBarLength: this.minBarLength
      }
    ]

    this.applicationLoginsLabels = apps.map(x => x.applicationLabel)
  }

  applicationLoginsOptions: ChartConfiguration['options'] = {
    scales: {
      y: {
        ticks: {
          precision: 0
        }
      }
    }
  }

  private setFailedLogins(data: DashboardLoginsViewModel[]): void {
    this.failedLoginsData =
      [
        {
          label: '',
          data: data.map(login => ({ x: login.timestamp.split('T')[0], y: login.failedCount })),
          backgroundColor: this.colors.lightBlue
        }
      ]
  }

  failedLoginsOptions: ChartConfiguration["options"] = {
    scales: {
      x: {
        type: 'time',
        time: {
          minUnit: 'day'
        }
      },
      y: {
        ticks: {
          precision: 0
        }
      }
    },
    plugins: {
      tooltip: {
        callbacks: {
          title: this.failedLoginTooltip
        }
      }
    }
  }

  failedLoginTooltip(data: any): string {
    return data[0].label.split(', 12:00:00')[0];
  }

  getTotalUsers(): void {
    this.subscriptions.add(
      this.authenticationsService.apiDashboardAuthenticationsTotalUsersOrganizationMnemonicGet(this.constantService.getSubdomain()).subscribe(data => {
        this.totalUsers = data;
      })
    )
  }

  getConfiguredApplications(): void {
    this.subscriptions.add(
      this.applicationSettingService.apiApplicationSettingGetPagedConfiguredAppOrganizationIdGet(this.orgId, '', 1, 10000).subscribe(data => {
        this.configuredApplications = data.result.filter(x => x.isConfigured).length;
      })
    )
  }

  getAllLogins(): void {
    this.authenticationsService.apiDashboardAuthenticationsAllLoginsOrganizationMnemonicGet(this.constantService.getSubdomain(), this.startDate.toISOString(), this.endDate.toISOString()).subscribe(data => {
      this.successfulLogins = data.successfulLogins;
      this.successfulLoginsForPeriod = data.successfulLoginsForPeriod;
      this.failedLogins = data.failedLogins;
      this.failedLoginsForPeriod = data.failedLoginsForPeriod;
    })
  }

}
