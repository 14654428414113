/**
 * Organization Microservice
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ThemeModel { 
    themeId?: number;
    organizationId?: number;
    themeType?: string | null;
    mainLogo?: string | null;
    signinLogo?: string | null;
    loginBackground?: string | null;
    signupBackground?: string | null;
    homeBackground?: string | null;
    primaryColor?: string | null;
    profileLogo?: string | null;
    resetBackground?: string | null;
    signOutBackground?: string | null;
    favicon?: string | null;
    secondaryColor?: string | null;
    textColor?: string | null;
    tokenText?: string | null;
    createdBy?: string | null;
    createdDate?: string;
    modifiedBy?: string | null;
    modifiedDate?: string;
    isActive?: boolean;
    isDeleted?: boolean;
    organizationName?: string | null;
    isTheme?: boolean;
}

