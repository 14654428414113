/**
 * Organization Microservice
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { IPRange } from './iPRange';


export interface NetworkPermissionModel { 
    allowed?: Array<IPRange> | null;
    restricted?: Array<IPRange> | null;
}

