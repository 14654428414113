/**
 * Organization Microservice
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type LicenceFeatures = 'None' | 'Integrations_LogonAgents' | 'Integrations_Radius' | 'Integrations_FederatedSignOn' | 'Directories_AD' | 'Directories_EntraID' | 'Directories_Google' | 'Directories_LDAP' | 'Auth_SMS' | 'Auth_SoftToken' | 'Auth_SoftTokenPush' | 'Auth_Email' | 'Auth_HardwareToken' | 'Auth_YubiKey' | 'Auth_Fido' | 'General_LocationMatrix' | 'General_ConditionalAccess' | 'General_PasswordReset' | 'General_ThemeCustomisation' | 'General_CustomReports';

export const LicenceFeatures = {
    None: 'None' as LicenceFeatures,
    IntegrationsLogonAgents: 'Integrations_LogonAgents' as LicenceFeatures,
    IntegrationsRadius: 'Integrations_Radius' as LicenceFeatures,
    IntegrationsFederatedSignOn: 'Integrations_FederatedSignOn' as LicenceFeatures,
    DirectoriesAd: 'Directories_AD' as LicenceFeatures,
    DirectoriesEntraId: 'Directories_EntraID' as LicenceFeatures,
    DirectoriesGoogle: 'Directories_Google' as LicenceFeatures,
    DirectoriesLdap: 'Directories_LDAP' as LicenceFeatures,
    AuthSms: 'Auth_SMS' as LicenceFeatures,
    AuthSoftToken: 'Auth_SoftToken' as LicenceFeatures,
    AuthSoftTokenPush: 'Auth_SoftTokenPush' as LicenceFeatures,
    AuthEmail: 'Auth_Email' as LicenceFeatures,
    AuthHardwareToken: 'Auth_HardwareToken' as LicenceFeatures,
    AuthYubiKey: 'Auth_YubiKey' as LicenceFeatures,
    AuthFido: 'Auth_Fido' as LicenceFeatures,
    GeneralLocationMatrix: 'General_LocationMatrix' as LicenceFeatures,
    GeneralConditionalAccess: 'General_ConditionalAccess' as LicenceFeatures,
    GeneralPasswordReset: 'General_PasswordReset' as LicenceFeatures,
    GeneralThemeCustomisation: 'General_ThemeCustomisation' as LicenceFeatures,
    GeneralCustomReports: 'General_CustomReports' as LicenceFeatures
};

