import { Directive, Input } from "@angular/core";
import { AbstractControl, FormControl, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn, Validators } from "@angular/forms";

export class CustomValidators {
  static patternValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        // if control is empty return no error
        return null;
      }

      // test the value of the control against the regexp supplied
      const valid = regex.test(control.value);

      // if true, return no error (no error), else return error passed in the second parameter
      return valid ? null : error;
    };
  }
  static onlySpaceValidator(control: AbstractControl): ValidationErrors | null {
    if (control.value !== '' && ((control && control.value && control.value.toString() || '').trim().length === 0)) {
      return { OnlySpace: true }
    }
    return null;
  }

  static dateAfterTodayValidator(c: FormControl) {
    const today = new Date();
    let value = new Date(c.value);
    if (value > today) {
      return null;
    } else {
      return { dateAfterToday: { valid: false } };
    }
  }
}

@Directive({
  selector: '[se_max][formControlName],[se_max][formControl],[se_max][ngModel]',
  providers: [{ provide: NG_VALIDATORS, useExisting: SEMaxDirective, multi: true }]
})
export class SEMaxDirective implements Validator {
  @Input()
  se_max: number;

  validate(c: FormControl): { [key: string]: any } {
    let v = c.value;
    return (v > this.se_max) ? { "max": true } : null;
  }
}

@Directive({
  selector: '[se_min][formControlName],[se_min][formControl],[se_min][ngModel]',
  providers: [{ provide: NG_VALIDATORS, useExisting: SEMinDirective, multi: true }]
})
export class SEMinDirective implements Validator {
  @Input()
  se_min: number;

  validate(c: FormControl): { [key: string]: any } {
    let v = c.value;
    return (v < this.se_min) ? { "min": true } : null;
  }
}
