import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-appikeys-confirm-modal',
  templateUrl: 'appikeys-confirm-modal.html'
})

export class APIKeysConfirmModal{

    destroy$: Subject<boolean> = new Subject<boolean>();

    constructor( private activeModal: NgbActiveModal) {}

    closeModal(isContinue:boolean){
      this.activeModal.close(isContinue);
    }
}