import { Component, OnInit } from '@angular/core';
import { VersionService as masterVersionService } from 'src/app/services/master';
import { VersionService as analyticsVersionService } from 'src/app/services/analytics';
import { VersionService as userVersionService } from 'src/app/services/user';
import { VersionService as filestoreVersionService } from 'src/app/services/fileStore';
import { VersionService as activityLoggingVersionService } from 'src/app/services/activitylogging';
import { VersionService as notificationVersionService } from 'src/app/services/notification';
import { VersionService as identityVersionService } from 'src/app/services/identity';
import { VersionService as organizationVersionService } from 'src/app/services/organization';
import { VersionService as conditionalAccessVersionService } from '../../services/conditional-access';
import { VersionService as migrationVersionService } from '../../services/migration';
import { VersionService as reportVersionService } from '../../services/report';



@Component({
  selector: 'app-system-details',
  templateUrl: './system-details.component.html',
  styleUrls: ['./system-details.component.scss']
})
export class SystemDetailsComponent implements OnInit {
  services: any[] = [];
  

  constructor(
    private masterVersionService: masterVersionService,
    private analyticsVersionService: analyticsVersionService,
    private filestoreVersionService: filestoreVersionService,
    private activityLoggingVersionService: activityLoggingVersionService,
    private notificationVersionService: notificationVersionService,
    private userVersionService: userVersionService,
    private identityVersionService: identityVersionService,
    private reportVersionService: reportVersionService,
    private organizationVersionService: organizationVersionService,
    private conditionalAccessVersionService: conditionalAccessVersionService,
    private migrationVersionService: migrationVersionService
  ) { }

  ngOnInit() {

    this.analyticsVersionService.apiVersionGetVersionGet().subscribe({
      next: (result) => this.subscribeResult('Analytics Api', result, false),
      error: (err) => this.subscribeResult('Analytics Api', err, true),
      complete: () => {}
    })

    this.userVersionService.apiVersionGetVersionGet().subscribe({
      next: (result) => this.subscribeResult('User Api', result, false),
      error: (err) => this.subscribeResult('User Api', err, true),
      complete: () => {}
    })

    this.filestoreVersionService.apiVersionGetVersionGet().subscribe({
      next: (result) => this.subscribeResult('File Store Api', result, false),
      error: (err) => this.subscribeResult('File Store Api', err, true),
      complete: () => {}
    })

    this.activityLoggingVersionService.apiVersionGetVersionGet().subscribe({
      next: (result) => this.subscribeResult('Activity Logging Api', result, false),
      error: (err) => this.subscribeResult('Activity Logging Api', err, true),
      complete: () => {}
    })

    this.masterVersionService.apiVersionGetVersionGet().subscribe({
      next: (result) => this.subscribeResult('Master Api', result, false),
      error: (err) => this.subscribeResult('Master Api', err, true),
      complete: () => {}
    })

    this.notificationVersionService.apiVersionGetVersionGet().subscribe({
     next: (result) => this.subscribeResult('Notification Api', result, false),
     error: (err) => this.subscribeResult('Notification Api', err, true),
     complete: () => {}
    })

    this.identityVersionService.apiVersionGetVersionGet().subscribe({
      next: (result) => this.subscribeResult('Identity Api', result, false),
      error: (err) => this.subscribeResult('Identity Api', err, true),
      complete: () => {}
    })

    this.organizationVersionService.apiVersionGetVersionGet().subscribe({
      next: (result) => this.subscribeResult('Organization Api', result, false),
      error: (err) => this.subscribeResult('Organization Api', err, true),
      complete: () => {}
    })

    this.conditionalAccessVersionService.versionGetVersionGet().subscribe({
      next: (result) => this.subscribeResult('Conditional Access Api', result, false),
      error: (err) => this.subscribeResult('Conditional Access Api', err, true),
      complete: () => {}
    })

    this.reportVersionService.versionGetVersionGet().subscribe({
      next: (result) => this.subscribeResult('Reports Api', result, false),
      error: (err) => this.subscribeResult('Reports Api', err, true),
      complete: () => {}
    })

    this.migrationVersionService.apiVersionGetVersionGet().subscribe({
      next: (result) => this.subscribeResult('Migration Api', result, false),
      error: (err) => this.subscribeResult('Migration Api', err, true),
      complete: () => {}
    })
    
  };
  
  subscribeResult(apiName: string, response: object, isErr): void {
    let responseObj: object = { api: apiName, isErr: isErr, ...response };
    this.services.push(responseObj);
    this.services.sort((a, b) => (a.api < b.api ? -1 : 1));
  }

}


