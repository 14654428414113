export * from './application.service';
import { ApplicationService } from './application.service';
export * from './country.service';
import { CountryService } from './country.service';
export * from './directory.service';
import { DirectoryService } from './directory.service';
export * from './downloads.service';
import { DownloadsService } from './downloads.service';
export * from './employeeType.service';
import { EmployeeTypeService } from './employeeType.service';
export * from './jobTitle.service';
import { JobTitleService } from './jobTitle.service';
export * from './messageTemplate.service';
import { MessageTemplateService } from './messageTemplate.service';
export * from './predefinedAttribute.service';
import { PredefinedAttributeService } from './predefinedAttribute.service';
export * from './predefinedAttributeMapping.service';
import { PredefinedAttributeMappingService } from './predefinedAttributeMapping.service';
export * from './questionSecret.service';
import { QuestionSecretService } from './questionSecret.service';
export * from './radiusService.service';
import { RadiusServiceService } from './radiusService.service';
export * from './releaseNotes.service';
import { ReleaseNotesService } from './releaseNotes.service';
export * from './systemSetting.service';
import { SystemSettingService } from './systemSetting.service';
export * from './version.service';
import { VersionService } from './version.service';
export const APIS = [ApplicationService, CountryService, DirectoryService, DownloadsService, EmployeeTypeService, JobTitleService, MessageTemplateService, PredefinedAttributeService, PredefinedAttributeMappingService, QuestionSecretService, RadiusServiceService, ReleaseNotesService, SystemSettingService, VersionService];
