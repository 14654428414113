/**
 * Organization Microservice
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UserOrgModel } from './userOrgModel';
import { RegistrationResult } from './registrationResult';


export interface OrganizationAndUserModel { 
    organizationId?: number;
    organizationName: string;
    mnemonic?: string | null;
    contactName?: string | null;
    contactEmail: string;
    emailDisclaimer?: string | null;
    websiteUrl?: string | null;
    organizationLogo?: string | null;
    isActive?: boolean;
    modifiedBy?: string | null;
    noOfLicense?: number | null;
    noOfActiveLicense?: number | null;
    recaptchaClientResponse?: string | null;
    publicUrl?: string | null;
    organizationDomain?: string | null;
    managedByOrgID?: number | null;
    organizationType?: string | null;
    isDeleted?: boolean;
    onTrial?: boolean;
    trialExpiryDate?: string;
    licenseExpiryDate?: string | null;
    smsLeft?: number;
    smsLimit?: number;
    mspAssignSmsLimit?: number;
    mspAssignSmsLeft?: number;
    count?: number;
    assignedMSP?: string | null;
    childOrganizationCount?: number;
    registrationResult?: RegistrationResult;
    uniqueId?: string | null;
    isLicenceLocalFile?: boolean;
    userOrgModel?: UserOrgModel;
}

