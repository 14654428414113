/**
 * Organization Microservice
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface OrganizationSettingModel { 
    organizationSettingId?: number;
    key?: string | null;
    value?: string | null;
    groupKey?: string | null;
    description?: string | null;
    settingComponent?: string | null;
    organizationId: number;
    organizationMnemonic?: string | null;
    isActive?: boolean;
    isCustomGatwaySetting?: boolean;
}

