import { Component, OnInit } from '@angular/core';
import { ConstantService, ThemeEnum } from '../../services/common/constant.service';
import { AuthService } from '../auth.service';
import { SharedAuthenticationService } from '../shared/shared-authentication.service';

@Component({
  selector: 'app-enforce-enrollment',
  templateUrl: './enforce-enrollment.component.html',
  styleUrls: ['./enforce-enrollment.component.scss']
})
export class EnforceEnrollmentComponent implements OnInit {

  userId: number;
  username: string;
  orgId: number;
  orgName: string;

logoImage: string
  
  constructor(
    private authService: AuthService,
    private constantService: ConstantService,
private sharedService: SharedAuthenticationService
  ) { }

  ngOnInit(): void {
    this.userId = this.authService.user.profile.UserID;
    this.username = this.authService.userName;
    this.orgId = this.authService.organizationId;
    this.orgName = this.constantService.getSubdomain();

    const logoImage = this.constantService.getSessionStorage(ThemeEnum.mainLogo);
    this.logoImage = logoImage ?? "assets/images/se-white-logo.png";
  }

  logout(): void {
    this.sharedService.delayedSignout();
  }

  async continue(): Promise<void> {
    if (!this.authService.$enforceData._value){
      await this.sharedService.getUserDetail();
    }
    sessionStorage.setItem('dataconf', '');
    this.authService.$enforceData._value.isEnforcedSecondFactor = false;
    this.authService.redirectEnforceView(this.authService.$enforceData._value, false);
  }

}
