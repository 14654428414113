/**
 * Activtiy Logging Microservice
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SearchParameterBase } from './searchParameterBase';


export interface ESPageRequest { 
    sortOrder?: string | null;
    searchFilter?: Array<SearchParameterBase> | null;
    sortColumn?: string | null;
    currentPage?: number;
    pageSize?: number;
    searchCriteria?: Array<string> | null;
    skip?: number | null;
}

