import { Component, OnInit, AfterViewInit } from '@angular/core';
import { timer } from 'rxjs';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-signin-redirect',
  templateUrl: './signin-redirect.component.html',
  styleUrls: ['./signin-redirect.component.scss']
})
export class SigninRedirectComponent implements OnInit, AfterViewInit {

  constructor(private authService: AuthService) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.signin();
  }


  signin() {
    timer(100).subscribe(() => {
      this.authService.login();
    });
  }

}
