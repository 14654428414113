import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { ConstantService } from '../../../services/common/constant.service';
import { FidoHelpersService } from '../../../services/common/fido-helpers.service';
import { ValidationService } from '../../../services/common/validation.service';
import { AuthenticationTypeService } from '../../../services/identity';
import { UsersService } from '../../../services/user';
import { ConfirmDialogComponent } from '../../confirm-dialog/confirm-dialog.component';
import { UsernamelessModalComponent } from './usernameless-modal/usernameless-modal.component';

@Component({
  selector: 'app-fido-token',
  templateUrl: './fido-token.component.html',
  styleUrls: ['./fido-token.component.scss']
})
export class FidoTokenComponent implements OnInit, OnDestroy {

  subscriptions: Subscription = new Subscription;
  private destroy$: Subject<boolean> = new Subject<boolean>();

  fidoForm: FormGroup;

  smsValidators = Validators.compose([Validators.required, Validators.pattern(this.validationService.regPhoneNumber)]);
  emailValidators = Validators.compose([Validators.required, Validators.pattern(this.validationService.regEmail)]);

  isRegistering: boolean;

  isSmsEnable: boolean = true;
  isEmailEnable: boolean = true;

  removedTokens: string[] = [];


  @Input() orgId: number;
  @Input() orgName: string;
  @Input() username: string;
  @Input() isForLoggedInUser: boolean;
  @Input() allowMultipleTokens: boolean;
  @Input() allowResidentKey: boolean;
  @Input() userEmail: string;
  @Input() userPhone: string;
  @Input() sequence: number;


  @Output() credential: EventEmitter<FidoCredentialOutput> = new EventEmitter<FidoCredentialOutput>();

  constructor(
    private constantService: ConstantService,
    private authTypeService: AuthenticationTypeService,
    private fidoHelpers: FidoHelpersService,
    private translateService: TranslateService,
    private toastrService: ToastrService,
    private fb: FormBuilder,
    private validationService: ValidationService,
    private userService: UsersService,
    private ngbModal: NgbModal
  ) { }


  get fidoCredentials(): FormArray {
    return this.fidoForm.controls.fidoCredentials as FormArray;
  }

  get fidoCredentialsControls(): FormGroup[] {
    return this.fidoCredentials.controls as FormGroup[];
  }

  get backupMethod(): FormControl {
    return this.fidoForm.controls.backupMethod as FormControl;
  }

  get sms(): FormControl {
    return this.fidoForm.controls.sms as FormControl;
  }
  get email(): FormControl {
    return this.fidoForm.controls.email as FormControl;
  }

  ngOnInit(): void {
    if (this.sequence != 1) {
      this.allowResidentKey = false;
    }

    this.fidoForm = this.fb.group({
      fidoCredentials: this.fb.array([],Validators.required),
      residentKey: [false, Validators.required],
      backupMethod: [null],
      sms: [this.userPhone, this.smsValidators],
      email: [this.userEmail, this.emailValidators]
    })

    this.backupMethod.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((method) => {
      if (method === 'SMS') {
        this.email.disable();
        this.sms.enable();
      }

      if (method === 'Email') {
        this.sms.disable();
        this.email.enable();
      }
      this.email.updateValueAndValidity();
      this.sms.updateValueAndValidity();
    });

    this.fidoForm.statusChanges.pipe(takeUntil(this.destroy$)).subscribe(_ => {
      if (this.fidoForm.valid) {
        this.returnOutput();
      } else {
        this.returnNull();
      }
    })

    this.backupMethod.setValue('SMS');
    
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  setValue(val: any): void {
    this.backupMethod.setValue(val.backup.method);
    if (val.backup.method == 'SMS') {
      this.sms.setValue(val.backup.recipient);
    } else {
      this.email.setValue(val.backup.recipient);
    }
    var creds = val.credentials.map(x => (
      {
        id: x.id,
        name: x.name,
        regDate: x.regDate,
        allowUsernameless: x.allowUsernameless,
        rawId: '',
        type: 'public-key',
        response: {},
        extensions: {}
      }
    ));
    creds.forEach((x, i) => {
      let form = this.fb.group({
        name: [x.name, Validators.required],
        allowUsernameless: [x.allowUsernameless],
        attestation: [x, Validators.required]
      })
      if (!this.isForLoggedInUser) {
        form.controls.name.disable();
      }
      this.fidoCredentials.push(form);
    })
    
    if (!this.sms.value || !this.email.value) {
      this.getUserDetails();
    }
  }

  getUserDetails(): void {
    let apiToCall = this.isForLoggedInUser ? this.userService.apiUsersGetUserBasicInfoGet() : this.userService.apiUsersGetUserBasicInfoForUserUserNameGet(this.username, this.orgId);
    this.subscriptions.add(apiToCall.subscribe(data => {
      if (!this.sms.value) {
        this.sms.setValue(data.cell);
      }
      if (!this.email.value) {
        this.email.setValue(data.email);
      }

    }))
  }

  promptUsernameless(): void {
    if (this.allowResidentKey) {
      const modalRef = this.ngbModal.open(UsernamelessModalComponent, {
        size: 'md',
        windowClass: 'fadeInUp animated huge',
        backdrop: 'static',
        keyboard: false,
      });

      modalRef.result.then((userResponse: boolean) => {
        this.registerFidoToken(userResponse);
      })
    } else {
      this.registerFidoToken(false);
    }
    
  }

  registerFidoToken(usernameless: boolean): void {
    this.isRegistering = true;


    if (!this.sms.value || !this.email.value) {
      this.getUserDetails();
    }
    const orgName = this.orgName && this.orgName.length > 0 ? this.orgName : this.constantService.getSubdomain();
    this.authTypeService.apiAuthenticationTypeFidoCredentialOptionsOrganizationMnemonicUserNameGet(orgName, this.username, usernameless).subscribe(async model => {
      let data = model.options;
      if (data.status !== 'ok') {
        console.log('error', data.errorMessage);
        return;
      }
      data.excludeCredentials = data.excludeCredentials.filter(x => this.removedTokens.indexOf(x.id) == -1);
      for (let cred of this.fidoCredentials.controls) {
        const id = cred.value.attestation.id;
        if (data.excludeCredentials.indexOf(id) == -1) {
          data.excludeCredentials.push({ type: 'public-key', id: id });
        }
      }
      let options = this.fidoHelpers.formatCredentialOptions(data);
      let newCredential;
      try {
        newCredential = await navigator.credentials.create({ publicKey: options });
      }
      catch (e) {
        if (e.toString().indexOf('contains one of the credentials already registered') > 0) {
          this.toastrService.error(this.translateService.instant('MyProfile.FidoAlreadyInUseMessage'), this.translateService.instant('MyProfile.FidoAlreadyInUse'), this.constantService.ToastError);
        } else {
          this.toastrService.error(e, this.translateService.instant('Action.Error'), this.constantService.ToastError);
        }
        console.log('Error creating credentials in browser', e);
      }
      this.isRegistering = false;

      const formattedCredential = this.fidoHelpers.formatCredential(newCredential);
      if (!this.allowMultipleTokens) {
        this.fidoCredentials.clear()
      }
      const fg = this.fb.group({
        optionsId: [model.id],
        name: [this.translateService.instant('MyProfile.DefaultTokenName', { index: this.fidoCredentials.length + 1 }), Validators.required],
        allowUsernameless: [usernameless],
        attestation: [formattedCredential, Validators.required]
      })
      this.fidoCredentials.push(fg);
    })

  }

  returnOutput(): void {
    let output: FidoCredentialOutput = {};
    output.attestation = this.fidoCredentials.value;
    output.backupMethod = this.backupMethod.value;
    if (output.backupMethod == 'SMS') {
      output.backupData = this.sms.value;
    } else {
      output.backupData = this.email.value;
    }
    this.credential.emit(output);
  }

  returnNull(): void {
    this.credential.emit({});
  }

  removeToken(index: number): void {
    const modalRef = this.ngbModal.open(ConfirmDialogComponent, {
      size: 'md',
      windowClass: 'fadeInUp animated huge',
      backdrop: 'static',
      keyboard: false,
    });

    modalRef.componentInstance.message = this.translateService.instant('MyProfile.ConfirmDeleteTokenMessage');

    modalRef.result.then((userResponse: boolean) => {
      if (userResponse) {
        const cred = this.fidoCredentials.at(index).value.attestation;
        this.removedTokens.push(this.fidoHelpers.coerceToBase64Url(cred.id));
        this.fidoCredentials.removeAt(index)
      }
    })
  }

  identifyToken(index: number): void {
    const modalRef = this.ngbModal.open(ConfirmDialogComponent, {
      size: 'md',
      windowClass: 'fadeInUp animated huge',
      backdrop: 'static',
      keyboard: false,
    });

    modalRef.componentInstance.message = this.translateService.instant('MyProfile.IdentifyTokenMessage');

    modalRef.result.then((userResponse: boolean) => {
      if (userResponse) {
        const cred = this.fidoCredentials.at(index).value.attestation;
        this.subscriptions.add(
          this.authTypeService.apiAuthenticationTypeIdentifyTokenOrganizationMnemonicUserNamePost(this.orgName, this.username, `"${cred.id}"`).subscribe(async data => {
            const options = this.fidoHelpers.formatAssertionOptions(data);
            try {
              await navigator.credentials.get({ publicKey: options })
            }
            catch (err) {
              this.toastrService.error(this.translateService.instant('Action.Error'), this.translateService.instant('Action.Error'), this.constantService.ToastError);
              console.log(err);
            }
          })
        )
      }
    })
  }
}

export interface FidoCredentialOutput {
  attestation?: any,
  backupMethod?: string,
  backupData?: string
}
