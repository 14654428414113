import { Directive, ElementRef, Injectable, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { User } from 'oidc-client';
import { AuthService } from 'src/app/authentication/auth.service';
import { UserRoleLevels, UserRoles } from './constant.service';

@Injectable({
  providedIn: 'root',
})
export class HasPermissionService {
  private roles = [];
  private targetRole = UserRoles.User;

  constructor(private authService: AuthService) {
  }

  async ngOnInit() {
  }

  public allowAccess(val: UserRoles[]) {
    this.roles = val;
    return this.checkPermission();
  }

  private checkPermission() {
    let hasPermission = false;
    const userType = this.userType;
    if (userType) {
      const found = this.roles.find(x => x.toUpperCase() === userType.toUpperCase());
      hasPermission = !this.isNullOrUndef(found);
    }
    return hasPermission;
  }

  get userType(): string {
    return this.authService.user?.profile?.UserType;
  }

  private isNullOrUndef(value: any) {
    return value === null || value === undefined;
  }

  allowAccessForTarget(val: UserRoles): boolean {

    this.targetRole = val;
    // levels for current and target user roles
    let currentuserlevel = UserRoleLevels[this.userType.toLowerCase()];
    let targetuserlevel = UserRoleLevels[this.targetRole.toLowerCase()];

    if (currentuserlevel && targetuserlevel && currentuserlevel >= targetuserlevel) {
      return true;
    }

    return false;
  }
}

