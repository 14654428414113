import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Subscription } from 'rxjs';
import { ConstantService } from 'src/app/services/common/constant.service';

import { UserCredentialService } from 'src/app/services/identity';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-auth-callback',
  templateUrl: './auth-callback.component.html',
  styleUrls: ['./auth-callback.component.scss']
})
export class AuthCallbackComponent implements OnInit, OnDestroy {

  public error: boolean = false;
  private subscriptions: Subscription[] = [];

  constructor(
    private authService: AuthService,
    private router: Router,
    private userCredentialService: UserCredentialService,
    private constantService: ConstantService,
    private route: ActivatedRoute) { }

  async ngOnInit() {
    if (this.route.snapshot.fragment.includes('error')) {
      $("error").removeClass("error-hide");
      return this.error = true;
    }
   
    if (this.route.snapshot.queryParams['changePassword']) {
      this.authService.$isChangePassword.next(true);
      return;
    } 

    await this.authService.completeAuthentication();
    this.getUserDetail();
  }

  getUserDetail() {
    this.subscriptions.push(this.userCredentialService.apiUserCredentialGetUserPostLoginStatusGet().subscribe(
      (data) => {
        const userType = this.authService.userType;
        if (!userType || !data) {
          return this.error = true;
        }
        sessionStorage.removeItem('dataconf');
        this.authService.$enforceData.next(data);
        this.authService.redirectEnforceView(this.authService.$enforceData._value, false);
      },
      (error) => {
        console.log('==> login error', error);
        window.location.replace('/auth/logout');
      }));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

}
