/**
 * User Microservice
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface UserCredentialModel { 
    userCredentialID?: number;
    userID?: number;
    username?: string | null;
    email?: string | null;
    organizationMnemonic?: string | null;
    password?: string | null;
    newPassword?: string | null;
    modifiedBy?: string | null;
    modifiedDate?: string;
    isMustChangePassword?: boolean | null;
    questionSecretStatus?: string | null;
    modifiedById?: number | null;
    ignorePasswordHistory?: boolean;
}

