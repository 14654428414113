import { TitleCasePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ConstantService, ThemeEnum } from '../../services/common/constant.service';

@Component({
  selector: 'app-styled-message',
  templateUrl: './styled-message.component.html',
  styleUrls: ['./styled-message.component.scss']
})
export class StyledMessageComponent implements OnInit {

  logoUrl: string;
  title: string;
  message: string;
  iconClass: string;
  icon: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private constantService: ConstantService,
    private translate: TranslateService,
  ) { }

  ngOnInit(): void {
    this.logoUrl = this.constantService.getImageFromServer(ThemeEnum.mainLogo) ?? "assets/images/se-white-logo.png";
    let messageKeyParam: string = this.activatedRoute.snapshot.params.message;
    let messageKey: string = messageKeyParam.replace('-', ' ');
    const pipe = new TitleCasePipe();
    messageKey = pipe.transform(messageKey).replace(' ', '');
    if (Object.values(Messages).includes(messageKey)) {
      this.title = this.translate.instant(`StyledMessage.${messageKey}Title`);
      this.message = this.translate.instant(`StyledMessage.${messageKey}Message`);
    }
    if (messageKeyParam.indexOf('-success') > 0) {
      this.iconClass = 'success';
      this.icon = 'fa-check-circle'
    }
    else if (messageKeyParam.indexOf('-error') > 0) {
      this.iconClass = 'danger';
      this.icon = 'fa-exclamation-circle';
    }
  }

  

}

enum Messages {
  'AuthenticationSuccess',
  'AuthenticationError'
}
