import { Injectable } from '@angular/core';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class ValidationService {

  constructor() { }

  public regOnlyAlphabetic = '^(?!\\s)[a-zA-Z_\\s-]*$';
  public regAlphaNumeric = '^[^-\\s][a-zA-Z0-9_\\s-]+$';
  public regEmail = '[_a-zA-Z0-9\-+]+(\\.[_a-zA-Z0-9\-]+)*@[a-zA-Z0-9-]+(\\.[a-zA-Z0-9-]+)*(\\.[a-zA-Z]{2,15})';
  public regUserEmail = '[^<>!=`*!@#$%&*()=^]+(\\.[^<>!=`*!@#$%&*()=+^])*@[a-zA-Z0-9-]+(\\.[a-zA-Z0-9-]+)*(\\.[a-zA-Z]{1,100})'
  public regOnlyNumber = '[0-9]+';
  public regGeneralPhone = '[0-9 +]+';
  public regAlphaNumWithOutSpcae = '^[a-zA-Z0-9]*$';
  public regCustomAttribute = '^[a-zA-Z0-9:_]*$';
  public regAlphaNumWithOutSpcaeDot = '^[a-zA-Z0-9]*$';
  public regPassword = '^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]{8,}$';
  public regGroupName = '^[^-\\s][a-zA-Z0-9. -]*$';
  public regAddSpaceCharacterInput = /^(.{3})(.{3})(.{3})(.{4})(.*)$/;
  public regAddSpaceCharacterInputThirdParty = /^(.{4})(.{4})(.{4})(.{4})(.*)$/;
  public regAddSpaceCharacterOutput = '$1 $2 $3 $4 $5';
  public regNoSpaceAndSpecialcChar = '/\\`|\\~|\\!|\\ |\\#|\\$|\\%|\\^|\\&|\\*|\\(|\\)|\\+|\\=|\\[|\\{|\\]|\\}|\\||\\\\|\\\'|\\<|\\,|\\>|\\?|\\/|\\;|\\:|\\s/g';
  public regUserName = '^[a-zA-Z0-9._-]*$';
  public regPhoneNumber = '^[ 0-9()+-]*$';
  public regGeneralField = '^[ A-Za-z0-9_$@./#+-]*$';
  public regIPAddress = '^([01]?\\d\\d?|2[0-4]\\d|25[0-5])\\.([01]?\\d\\d?|2[0-4]\\d|25[0-5])\\.([01]?\\d\\d?|2[0-4]\\d|25[0-5])\\.([01]?\\d\\d?|2[0-4]\\d|25[0-5])$';
  public regGsuitCustomAttr = '[_a-zA-Z0-9\-]*:[a-zA-Z0-9-]{1,1000}';
  public reghttps = '^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$';
  public regAccuracy = '[0-9]+(\.[0-9]{1,2})?';
  public regZerotoTwelve = '([1-9]|1[0-2])';
  public regMinThirty = '([3-9][0-9])|([2-9][0-9][0-9])|([1-9]\d{3}\d*)';
  public regNoStartingSpace = /^[^\s]+[a-zA-Z\d-_]*/;
  public regTenantMnemonic = '^([a-z]|[0-9]|-)*$';
  public regGuid = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

  OnlySpaceValidator(control) {
    return (control && control.value && control.value.toString() || '').trim().length === 0;
  }

  onlyMinusValidator(event) {
    const k = event.charCode;
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57));
  }

  public static emailArrayValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {

      const emailsAllValid = control.value.every((value: string) => {
        return this.validateEmail(value);
      });
      if (emailsAllValid) {
        return null;
      }
      return { 'emailIsInvalid': true };
    }
  }
  static validateEmail(email: string): boolean {
    // Email Regex from: https://github.com/angular/angular/blob/master/packages/forms/src/validators.ts
    const EMAIL_REGEXP =
      /^(?=.{1,254}$)(?=.{1,64}@)[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+(\.[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+)*@[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?(\.[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?)*$/;
    return EMAIL_REGEXP.test(String(email).toLowerCase());
  }

}
