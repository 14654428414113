import { Component, OnDestroy, OnInit, Input } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { MyAppGroupModel, MyAppGroupService } from 'src/app/services/user';

@Component({
  selector: 'app-edit-group-modal',
  templateUrl: './edit-group-modal.component.html',
  styleUrls: ['./edit-group-modal.component.scss']
})
export class EditGroupModalComponent implements OnInit {
  public editMyAppGroupForm: FormGroup;
  @Input() myAppGroupmodel: MyAppGroupModel;
  @Input() userId;

  constructor(
    private fb: FormBuilder,
    private toastrService: ToastrService,
    private translateService: TranslateService,
    public activeModal: NgbActiveModal,
    private myAppGroupService: MyAppGroupService
  ) { }

  ngOnInit(): void {
    this.initForm();
    this.myAppGroupmodel.userId = this.userId;
  }
  private initForm() {
    this.editMyAppGroupForm = this.fb.group({
      groupName: [this.myAppGroupmodel.groupName, Validators.compose([Validators.required])],
    });
  }

  public save() {
    if (!this.editMyAppGroupForm.controls.groupName.errors?.required) {
      this.myAppGroupmodel.groupName = this.editMyAppGroupForm.value.groupName;
      this.myAppGroupService.apiMyAppGroupUpdateMyAppGroupMyAppGroupPost(this.myAppGroupmodel).subscribe(data => {
        this.toastrService.success(this.translateService.instant('Action.Success'));
        this.activeModal.close(true);
      })

    }

  }

}
