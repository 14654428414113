/**
 * Identity Server Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type AuthenticationMethodRadius = 'Password' | 'PassCode' | 'UserCode' | 'Push' | 'Hardware' | 'Yubikey';

export const AuthenticationMethodRadius = {
    Password: 'Password' as AuthenticationMethodRadius,
    PassCode: 'PassCode' as AuthenticationMethodRadius,
    UserCode: 'UserCode' as AuthenticationMethodRadius,
    Push: 'Push' as AuthenticationMethodRadius,
    Hardware: 'Hardware' as AuthenticationMethodRadius,
    Yubikey: 'Yubikey' as AuthenticationMethodRadius
};

