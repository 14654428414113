/**
 * Organization Microservice
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface SafeLocationModel { 
    id?: number;
    placeId?: string | null;
    location?: string | null;
    longitude?: number;
    latitude?: number;
    radius?: number;
    country?: string | null;
    city?: string | null;
    organizationId?: number;
    organizationCode?: string | null;
    isActive?: boolean;
    createdBy?: string | null;
    createdOn?: string | null;
    updatedBy?: string | null;
    updatedOn?: string | null;
}

