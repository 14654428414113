import { environment } from "src/environments/environment";
import { Configuration as MasterConfiguration, ConfigurationParameters } from "../master";
import { Configuration as AnalyticsConfiguration } from "../analytics";
import { Configuration as UserConfiguration } from "../user";
import { Configuration as ActivityLoggingConfiguration } from "../activitylogging";
import { Configuration as FileStoreConfiguration } from "../fileStore";
import { Configuration as NotificationConfiguration } from "../notification";
import { Configuration as IdentityConfiguration } from "../identity";
import { Configuration as OrganizationCOnfiguration } from "../organization";
import { Configuration as ConditionalAccessConfiguration } from "../conditional-access"
import { Configuration as MigrationConfiguration } from "../migration"
import { Configuration as ReportConfiguration } from "../report"

export function getApi(api: string) {
  switch (api) {
    case 'master':
      return environment.master;
    case 'analytics':
      return environment.analytics;
    case 'user':
      return environment.user;
    case 'notification':
      return environment.notification;
    case 'filestore':
      return environment.filestore;
    case 'activitylogging':
      return environment.activityLogging;
    case 'identity':
      return environment.identity;
    case 'organization':
      return environment.organization;
    case 'conditionalAccess':
      return environment.conditionalAccess;
    case 'migration':
      return environment.migration;
    case 'report':
      return environment.report
    default:
      return '';
  }
};

export function apiMaster(): MasterConfiguration {
  const params: ConfigurationParameters = {
    basePath: getApi('master')
  }
  return new MasterConfiguration(params);
};

export function apiAnalytics(): AnalyticsConfiguration {
  const params: ConfigurationParameters = {
    basePath: getApi('analytics')
  }
  return new AnalyticsConfiguration(params);
};

export function apiUser(): UserConfiguration {
  const params: ConfigurationParameters = {
    basePath: getApi('user')
  }
  return new UserConfiguration(params);
};

export function apiNotification(): NotificationConfiguration {
  const params: ConfigurationParameters = {
    basePath: getApi('notification')
  }
  return new NotificationConfiguration(params);
};
export function apiFileStore(): FileStoreConfiguration {
  const params: ConfigurationParameters = {
    basePath: getApi('filestore')
  }
  return new FileStoreConfiguration(params);
};
export function apiActivitylogging(): ActivityLoggingConfiguration {
  const params: ConfigurationParameters = {
    basePath: getApi('activitylogging')
  }
  return new ActivityLoggingConfiguration(params);
};

export function apiIdentity(): IdentityConfiguration {
  const params: ConfigurationParameters = {
    basePath: getApi('identity')
  }
  return new IdentityConfiguration(params);
};

export function apiOrganization(): OrganizationCOnfiguration {
  const params: ConfigurationParameters = {
    basePath: getApi('organization')
  }
  return new OrganizationCOnfiguration(params);
};

export function apiConditionalAccess(): ConditionalAccessConfiguration {
  const params: ConfigurationParameters = {
    basePath: getApi('conditionalAccess')
  }
  return new ConditionalAccessConfiguration(params);
};

export function apiMigration(): MigrationConfiguration {
  const params: ConfigurationParameters = {
    basePath: getApi('migration')
  }
  return new MigrationConfiguration(params);
};

export function apiReport(): ReportConfiguration {
  const params: ConfigurationParameters = {
    basePath: getApi('report')
  }
  return new ReportConfiguration(params);
};
