/**
 * Organization Microservice
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { TimeSpan } from './timeSpan';
import { LicenceFeatures } from './licenceFeatures';
import { SmsAllowancePeriod } from './smsAllowancePeriod';
import { GracePeriodType } from './gracePeriodType';
import { LicenceType } from './licenceType';
import { BillingType } from './billingType';


export interface LicenceModel { 
    orgId?: string;
    type?: LicenceType;
    features?: LicenceFeatures;
    expiresUtc?: string;
    gracePeriodType?: GracePeriodType;
    gracePeriod?: TimeSpan;
    billingType?: BillingType;
    quantity?: number;
    smsAllowancePeriod?: SmsAllowancePeriod;
    smsAllowance?: number;
    isSelfHosted?: boolean;
    hardwareIds?: Array<string> | null;
}

