/**
 * User Microservice
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ChangeType } from './changeType';


export interface ADSyncDataModel { 
    data?: { [key: string]: any; } | null;
    name?: string | null;
    password?: string | null;
    members?: Array<string> | null;
    description?: string | null;
    organizationDirectoryId?: number;
    objectGuid?: string | null;
    objectType?: string | null;
    createdBy?: string | null;
    isDeleted?: boolean;
    changeType?: ChangeType;
    isConfiguredGroup?: boolean;
}

