/**
 * Master Microservice
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface AttributeMappingModel { 
    attributeMappingId?: number;
    directoryId: number;
    sourceColumnName: string;
    destinationColumnName: string;
    mappingType?: string | null;
    udTableName: string;
    isReadonly?: boolean;
    displayName?: string | null;
    description?: string | null;
    modifiedBy?: string | null;
    isActive?: boolean;
    isTypeReadOnly?: boolean;
}

