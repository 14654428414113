import { Component, isDevMode, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { ConstantService, ThemeEnum } from 'src/app/services/common/constant.service';
import { ValidationService } from 'src/app/services/common/validation.service';
import { AuthService } from '../auth.service';


@Component({
  selector: 'app-login',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {
  signOutBackground: string;
  logoImage: string;

  constructor(
    public validationService: ValidationService,
    private authService: AuthService,
    private router : Router,
    private constantService: ConstantService,) {
  }

  ngOnInit() {
    localStorage.removeItem('OrganizationCode');
    localStorage.removeItem('breadcrumbs');
    localStorage.removeItem('title');

    if (isDevMode) {
      var orgCode = localStorage.getItem('OrganizationCode');
      localStorage.setItem('OrganizationCode', orgCode);
    }

    this.signOutBackground = this.constantService.getImageFromServer(ThemeEnum.signOutBackground) ?? 'assets/images/logout.png';
    this.logoImage = this.constantService.getImageFromServer(ThemeEnum.signinLogo);
  }

  signin() {
    this.authService.login();
  }

  home() {
    this.router.navigateByUrl('/');
  }
}
