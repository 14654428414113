import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-custom-redirection',
  templateUrl: './custom.redirection.html'
})
export class AzureCustomRedirectionComponent implements OnInit {


  returnUrl: string;
  code: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {
    this.returnUrl = this.activatedRoute.snapshot.queryParams.state ?? null;
    this.code = this.activatedRoute.snapshot.queryParams.code ?? null;

    let redirect = '';
    if (this.returnUrl && this.code) {
      redirect = this.returnUrl + '?code=' + this.code;
    } else {
      redirect = this.returnUrl + '?authorizeError=true';
    }
    window.location.href = redirect;
  }

}
