/**
 * Notification Microservice
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DirectoryType } from './directoryType';


export interface DirectoryConfiguration { 
    zone?: string | null;
    domainName?: string | null;
    subdomainName?: string | null;
    netbiosName?: string | null;
    directoryType?: DirectoryType;
    authType?: number;
    groups?: Array<string> | null;
    importGroup?: string | null;
    userAccount?: string | null;
    organizationId?: number;
    directoryId?: number;
    organizationDirectoryId?: number;
    isImportGroup?: boolean;
    isExportGroup?: boolean;
    isImportUser?: boolean;
    isExportUser?: boolean;
    isImportCustomAttribute?: boolean;
    isExportCustomAttribute?: boolean;
    destination?: string | null;
    port?: number;
    address?: string | null;
    password?: string | null;
    username?: string | null;
}

