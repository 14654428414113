import { Component, isDevMode, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/authentication/auth.service';
import { ConstantService, HomeQueryStringEnum, ThemeEnum } from 'src/app/services/common/constant.service';
import { AppSettingsService } from 'src/app/services/common/appsettings.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss']
})
export class SigninComponent implements OnInit {
  mainLogo: string;
  homeBackground: string;
  version: string;
  allowRegister: boolean = false;

  constructor(
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private constantService: ConstantService  ) {
      this.version = environment.apiVersion;
     }

  ngOnInit() {
    sessionStorage.setItem('dataconf', '');
    this.constantService.resetTenant();
    
    if (this.authService.isAuthenticated()) {
      return this.authService.redirectUser();
    }

    const toastOption = {
      timeOut: 5000,
      preventDuplicates: false,
      enableHtml: true,
    };

    this.activatedRoute.queryParams.subscribe(params => {
      const app = params.app;
      if (app === HomeQueryStringEnum.error) {
        console.error(
          'Sorry, you can not access this app because you are not assigned to this app in' + this.constantService.showOrgName + '.' +
          + '<br> If you are wondering why this is happening, please contact your administrator. ', 'SSO', true, toastOption);
      }
    });

    this.mainLogo = this.constantService.getImageFromServer(ThemeEnum.mainLogo);
    this.homeBackground = this.constantService.getImageFromServer(ThemeEnum.homeBackground);

    this.allowRegister = !environment.singleTenantMode;

  }

  signin() {
    this.authService.login();
  }

}
