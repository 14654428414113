import { NgModule } from '@angular/core';
import { SecondFaModal } from './second-fa-modal/second-fa-modal';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { EnrollmentComponent } from './enrollment.component';
import { SharedModule } from '../shared.module';
import { FidoTokenComponent } from './fido-token/fido-token.component';
import { UsernamelessModalComponent } from './fido-token/usernameless-modal/usernameless-modal.component';
import { SoftTokenComponent } from './soft-token/soft-token.component';
import { LaddaModule } from 'angular2-ladda';

@NgModule({
  declarations: [
    SecondFaModal,
    EnrollmentComponent,
    FidoTokenComponent,
    UsernamelessModalComponent,
    SoftTokenComponent,
  ],
  imports: [
    SharedModule,
    NgxQRCodeModule
  ],
  exports:[EnrollmentComponent, FidoTokenComponent, SoftTokenComponent]
})

export class EnrollmentModule { }
