import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { ConstantService, ThemeEnum } from '../../../services/common/constant.service';
import { OrganizationQuestionSecretService, OrganizationQuestionsModelBinding } from '../../../services/organization';
import { AuthService } from '../../auth.service';

@Component({
  selector: 'app-security-questions-container',
  templateUrl: './security-questions-container.component.html',
  styleUrls: ['./security-questions-container.component.scss']
})
export class SecurityQuestionsContainerComponent implements OnInit {

  subscriptions: Subscription = new Subscription;

  logoImage: string;

  isChangePass: boolean;
  isAskAnswer: boolean = true;

  orgSettings: OrganizationQuestionsModelBinding

  constructor(
    private activatedRoute: ActivatedRoute,
    private constantService: ConstantService,
    private authService: AuthService
  ) {
  }

  ngOnInit(): void {
    const logoImage = this.constantService.getSessionStorage(ThemeEnum.mainLogo);
    this.logoImage = logoImage ?? "assets/images/se-white-logo.png";

    this.isAskAnswer = this.activatedRoute.snapshot.params.isAskAnswer ? JSON.parse(this.activatedRoute.snapshot.params.isAskAnswer) : false;
    this.isChangePass = this.activatedRoute.snapshot.params.isChangePass ? JSON.parse(this.activatedRoute.snapshot.params.isChangePass) : false;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  logout() {
    this.authService.signOut();
  }

}
