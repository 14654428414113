import { HttpBackend, HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { DEFAULT_INTERRUPTSOURCES, Idle } from "@ng-idle/core";
import { TranslateService } from "@ngx-translate/core";
import { User, UserManager, UserManagerSettings } from "oidc-client";
import { BehaviorSubject, interval, Observable, Subscription } from "rxjs";
import { environment } from "src/environments/environment";
import { AppSettingsService } from "../services/common/appsettings.service";
import { ConstantService, UserRoles } from "../services/common/constant.service";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  private authNavStatusSource = new BehaviorSubject<boolean>(false);
  authNavStatus$ = this.authNavStatusSource.asObservable();
  private queryString: any;
  private manager: UserManager;
  public user: User | null;
  public $enforceData;
  public $isChangePassword;
  stopRedirect: boolean = true;

  private httpClient: HttpClient;
  public sessionId: string;

  constructor(private activatedRoute: ActivatedRoute,
    private appSettings: AppSettingsService,
    private constantService: ConstantService,
    private translate: TranslateService,
    private router: Router,
    private httpBackend: HttpBackend
  ) {

    this.httpClient = new HttpClient(httpBackend);

    this.$isChangePassword = new BehaviorSubject<boolean>(false);
    this.$enforceData = new BehaviorSubject<any>(null);
    activatedRoute.queryParamMap.subscribe((params) => {
      this.stopRedirect = true;
      this.queryString = params;
      this.$isChangePassword.next(this.queryString.params['changePassword'] ? true : false);
    });

    const changePassword = activatedRoute?.snapshot?.children[0]?.url[0]?.path === 'change-password';
    this.manager = new UserManager(this.getClientSettings(changePassword));

    this.manager.events.addUserLoaded(e => {      
      this.manager.getUser().then(x => {
        this.user = x;
      });
      if (!this.sessionId) {
        this.getSessionId();
      }
    });


    this.manager.events.addAccessTokenExpired(e => {
      this.signOut();
    })

    this.getUserDetail();
  }

  public reloadPage() {
    let currentUrl = this.router.url;
    let redirect = this.getUrl();

    if (currentUrl == redirect) {
      this.router.navigateByUrl('/download', { skipLocationChange: true }).then(() => { this.router.navigate([currentUrl]); });
    } else {
      this.router.navigate([redirect]);
    }
  }

  getUserDetail() {
    this.manager.getUser().then((user) => {
      this.user = user;
      if (!this.sessionId) {
        this.getSessionId();
      }
      this.authNavStatusSource.next(this.isAuthenticated());
    });
  }

  async getUser() {
    this.user = await this.manager.getUser();
    return this.user;
  }

  async checkLogin() {
    if (!this.user) {
      await this.getUser();
    }
  }

  async login() {
    await this.manager.signinRedirect();
  }

  changePassword() {
    return this.manager.signinRedirect();
  }

  async completeAuthentication() {
    try {
      this.user = await this.manager.signinRedirectCallback();
      if (this.userType && this.userType.toLowerCase() === UserRoles.User.toLowerCase()) {
        this.appSettings.settings.adminMenu = false;
      }
      this.authNavStatusSource.next(this.isAuthenticated());
    } catch {
      this.router.navigate(["/"]);
    }
  }

  isAuthenticated(): boolean {
    return this.user?.expired;
  }

  get authorizationHeaderValue(): string {
    return this.user && `${this.user.token_type} ${this.user.access_token}`;
  }

  get userName(): string {
    return this.user?.profile?.UserName;
  }

  get userType(): string {
    return this.user?.profile?.UserType;
  }

  get organizationId(): number {
    return this.constantService.mspTenant?.organizationId ?? this.user?.profile?.OrganizationId;
  }

  get isLoginExpired(): boolean {
    return this.user?.expired;
  }

  get loginExpiryTime(): Date {
    const expiryTime = new Date(this.user?.profile?.LoginExpiryTime);
    return this.user && expiryTime;
  }

  get currentDateTime(): Date {
    const currentDateTime = new Date().toISOString().replace('Z', '');
    return new Date(currentDateTime);
  }

  get id(): string {
    return this.user?.profile?.UserID;
  }
  encode(value) {
    if (value) {
      return btoa(value);
    }
  }

  processUrl(url) {
    sessionStorage.setItem('dataconf', JSON.stringify(url));
    return url;
  }

  public async redirectEnforceView(data, stopRedirect?: boolean): Promise<any> {
    await this.getUser();

    this.stopRedirect = stopRedirect;

    if (data.questionSecretStatus === "MustAskQuestionSecret") {
      return this.router.navigate(this.processUrl(['verify-user', true, this.$isChangePassword._value ?? false]));
    }
    else if (data.questionSecretStatus === "MustConfigureQuestionSecret") {
      return this.router.navigate(this.processUrl(['verify-user', false, this.$isChangePassword._value ?? false]));
    }

    if (data.isMustChangePassword) {
      if (data.authEnforcement) {
        const authObject = JSON.parse(data.authEnforcement);
        return this.router.navigate(this.processUrl(['auth', 'change-password', this.encode(authObject.sequence), data.isEnforcedSecondFactor]));
      }
      if (data.isEnforcedSecondFactor) {
        return this.router.navigate(this.processUrl(['auth', 'change-password', data.isEnforcedSecondFactor]));
      }

      return this.router.navigate(this.processUrl(['auth', 'change-password']));
    }

    if (this.$isChangePassword._value) {
      return this.router.navigate(this.processUrl(['auth', 'change-password']));
    }
    if (data.authEnforcement) {
      const authObject = JSON.parse(data.authEnforcement);
      if (authObject.authenticationTypeName == 'FidoToken') {
        return this.router.navigate(this.processUrl(['enforce-fido', authObject.sequence, data.isEnforcedSecondFactor]));
      } else {
        return this.router.navigate(this.processUrl(['enforce-push', this.encode(authObject.sequence), data.isEnforcedSecondFactor]));
      }
    }
    if (data.isEnforcedSecondFactor) {
      return this.router.navigate(this.processUrl(['enforce-enrollment']));
    }

    if (!this.stopRedirect) {
      this.redirectUser();
    }
  }

  getUrl() {
    return this.appSettings.settings.rootUrl + (this.appSettings.settings.adminMenu == true ? '/dashboard' : '/myapplications');
  }

  public async loadLanguages() {
    let locale = localStorage.getItem('locale') ?? 'en';
    localStorage.setItem('locale', locale);
    this.translate.addLangs([locale]);
    this.translate.setDefaultLang(locale);
    await this.translate.use(locale).toPromise();
    this.constantService.setTableLang();
  }

  redirectUser() {
    switch (this.userType?.toLowerCase()) {
      case UserRoles.User:
        return this.router.navigate(['myapplications']);
      case UserRoles.MspAdmin:
      case UserRoles.AdminUser:
      case UserRoles.HelpDeskUser:
      case UserRoles.SuperAdmin:
        return this.router.navigate([this.getUrl()]);
    }
  }

  async signOut() {
    await this.manager.signoutRedirect();
  }

  silentRefresh(): any {
    this.manager.signinSilentCallback();
  }

  public getClientSettings(changePassword: boolean = false): UserManagerSettings {
    const subdomain = this.constantService.getSubdomain(false);
    let settings: UserManagerSettings = {
      authority: environment.identityApi.replace("{org_code}", subdomain),
      client_id: "UDClient",
      redirect_uri: changePassword ? environment.identityChangePassword.replace("{org_code}", subdomain) : environment.identityRedirect.replace("{org_code}", subdomain),
      post_logout_redirect_uri: environment.identityLogout.replace("{org_code}", subdomain),
      response_type: "id_token token",
      scope: "openid profile userapi orgapi masterapi loggingapi notificationapi conditionalaccessapi reportapi",
      filterProtocolClaims: true,
      loadUserInfo: true,
      automaticSilentRenew: true,
      silent_redirect_uri: environment.identitySilentRedirect.replace("{org_code}", subdomain)
    };

    return settings;
  }

  private getSessionId() {
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/json');
    let url = `${environment.identityApi}/api/UserSession/ActiveSession`;
    url = url.replace('{org_code}', this.constantService.getSubdomain(false));
    this.httpClient.get<string>(url, { headers: headers, withCredentials: true }).subscribe(data => {
      this.sessionId = data;
    });
  }
  
}


