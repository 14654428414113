import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { User } from 'oidc-client';
import { UserRoles } from './constant.service';
import { HasPermissionService } from './has-permission.service';

// Helps to find if the current logged in user role has access to do actions for the target role

@Directive({
  selector: '[hasPermissionForTarget]'
})
export class HasPermissionForTargetDirective implements OnInit {
  private targetRole:UserRoles;

  constructor(private templateRef: TemplateRef<any>,private viewContainer: ViewContainerRef, private permissions: HasPermissionService)
  {
  }

  async ngOnInit() {
    this.updateView();
  }

  @Input()
  set hasPermissionForTarget(val: UserRoles) {

    this.targetRole = val?val:UserRoles.User;
    this.updateView();
  }

  private updateView() {
    this.viewContainer.clear();
    if (this.permissions.allowAccessForTarget(this.targetRole)) {
        this.viewContainer.createEmbeddedView(this.templateRef);
    }
  }

}

