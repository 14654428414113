import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject, takeUntil } from 'rxjs';
import { UsersService } from 'src/app/services/user';
import { ToastrService } from 'ngx-toastr';
import { FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-phone-validation-modal',
  templateUrl: './phone-validation-modal.component.html'
})

export class PhoneValidationModal implements OnInit {

  destroy$: Subject<boolean> = new Subject<boolean>();
  isSent:boolean = false;
  phone:string = "";
  userInputCode: FormControl;
  userInputHasError: boolean = true;

  constructor(
    public activeModal: NgbActiveModal,
    private userService: UsersService,
    private toastrService: ToastrService,
  ) { }

  ngOnInit() {
    this.userInputCode = new FormControl('', Validators.required);
      this.userInputCode.valueChanges.pipe(
        takeUntil(this.destroy$)
      ).subscribe(val=>{
        if(val && val.length != 6){
          this.userInputCode.setErrors({'inValid': true});
          this.userInputHasError = true;
        }else{
          this.userInputCode.setErrors(null);
          this.userInputHasError = false;
        }
      });
  }

  sendSMS() {
    this.userService.apiUsersSendNewTenantValidationCodeSMSPut(`"${this.phone}"`).pipe(
      takeUntil(this.destroy$)
    ).subscribe({
      next: (result) => {
        if(result){
          this.isSent = true;
          this.toastrService.success('Validation SMS Sent');
        }else{
          this.toastrService.error('Invalid Number');
        }
      },
      error: () => {},
      complete: () => { 
        
      }
    });
  }

  verify(){
    if(this.userInputHasError){ return }

    this.userService.apiUsersVerifyNewTenantValidationCodeSMSPut(`"${this.userInputCode.value}"`).pipe(
      takeUntil(this.destroy$)
    ).subscribe({
      next: (result) => {
        if(result){
          this.toastrService.success('Validation Successful'); 
          setTimeout(() => {
            this.activeModal.close(true);
          }, 1000);
          return;
        }else{
          this.toastrService.error('Validation Failed');
          this.userInputCode.reset();
          this.userInputHasError = true;
        }
      },
      error: () => {},
      complete: () => {}
    });
  }

  cancel(){
    this.userInputCode.reset();
    this.isSent = false;
  }

  closeModal(){
    this.activeModal.close(false);
  }

  ngOnDestroy(){
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

}

