/**
 * SecurEnvoy.Migration
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { QueryResult } from './queryResult';


export interface UserData { 
    forgetPasswords?: QueryResult;
    users?: QueryResult;
    applications?: QueryResult;
    customAttributes?: QueryResult;
    directories?: QueryResult;
    groups?: QueryResult;
    groupApplications?: QueryResult;
    settings?: QueryResult;
}

