/**
 * Notification Microservice
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface UserAuthenticationModel { 
    mnemonic?: string | null;
    notificationId?: string | null;
    organizationDirectoryId?: number;
    username?: string | null;
    password?: string | null;
    directoryName?: string | null;
    authenticationStatus?: boolean;
}

