/**
 * Organization Microservice
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface OrganizationDirectoryModel { 
    organizationDirectoryId?: number;
    directoryId: number;
    organizationId: number;
    mnemonic?: string | null;
    settings?: string | null;
    directoryName?: string | null;
    status?: string | null;
    lastSync?: string | null;
    logoUrl?: string | null;
    isActive?: boolean;
    isDeleted?: boolean;
    directoryLabel?: string | null;
    agentID?: string | null;
}

