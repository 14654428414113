/**
 * Identity Server Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type AuthenticationMethod = 'Password' | 'PassCode' | 'UserCode' | 'Push' | 'Hardware' | 'Yubikey' | 'FidoToken';

export const AuthenticationMethod = {
    Password: 'Password' as AuthenticationMethod,
    PassCode: 'PassCode' as AuthenticationMethod,
    UserCode: 'UserCode' as AuthenticationMethod,
    Push: 'Push' as AuthenticationMethod,
    Hardware: 'Hardware' as AuthenticationMethod,
    Yubikey: 'Yubikey' as AuthenticationMethod,
    FidoToken: 'FidoToken' as AuthenticationMethod
};

