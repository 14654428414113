import { Component, OnDestroy, OnInit, Input } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { MyAppGroupApplicationService } from 'src/app/services/user';
@Component({
  selector: 'app-delete-my-app-group-application-modal',
  templateUrl: './delete-my-app-group-application-modal.component.html',
  styleUrls: ['./delete-my-app-group-application-modal.component.scss']
})
export class DeleteMyAppGroupApplicationModalComponent implements OnInit {
  public addMyAppGroupApplicationsForm: FormGroup
  @Input() userId;
  @Input() organizationId;
  @Input() applicationList;
  @Input() groupList;
  @Input() myAppGroupId;

  constructor(
    private fb: FormBuilder,
    private toastrService: ToastrService,
    private translateService: TranslateService,
    public activeModal: NgbActiveModal,
    public myAppGroupApplicationService: MyAppGroupApplicationService
  ) { }

  ngOnInit(): void {
    this.initForm();
  }
  private initForm() {
  }

  delete() {
    this.myAppGroupApplicationService.apiMyAppGroupApplicationDeletelMyAppGroupApplicationByIdUserIdOrganizationIdMyAppGroupIdDelete(this.userId, this.organizationId, this.myAppGroupId, this.applicationList).subscribe(data => {
      this.toastrService.success(this.translateService.instant('Action.Success'));
      this.activeModal.close(true);
    })
  }

}
