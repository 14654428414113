/**
 * Organization Microservice
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type GroupType = 'Member2FA_NonMember1FA' | 'NonMember2FA_Member1FA';

export const GroupType = {
    Member2FaNonMember1Fa: 'Member2FA_NonMember1FA' as GroupType,
    NonMember2FaMember1Fa: 'NonMember2FA_Member1FA' as GroupType
};

