import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { ApplicationSettingService } from '../services/organization';
import { UserApplicationService } from '../services/user';
import { AuthService } from 'src/app/authentication/auth.service';
import { ConstantService } from '../services/common/constant.service';
import { ApplicationStateService } from '../application/application.service';
import { NewGroupModalComponent } from './new-group-modal/new-group-modal.component';
import { AddApplicationToGroupModalComponent } from './add-application-to-group-modal/add-application-to-group-modal.component';
import { DeleteGroupModalComponent } from './delete-group-modal/delete-group-modal.component';
import { EditGroupModalComponent } from './edit-group-modal/edit-group-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MyAppGroupService, MyAppGroupModel } from '../services/user';
import { MyAppGroupApplicationService } from '../services/user';
import { DeleteMyAppGroupApplicationModalComponent } from './delete-my-app-group-application-modal/delete-my-app-group-application-modal.component';

@Component({
  selector: 'app-mypplications',
  templateUrl: './mypplications.component.html',
  styleUrls: ['./mypplications.component.scss'],
})
export class MyApplicationsComponent implements OnInit {
  userId = this.authService.user.profile.UserID;
  organizationId = this.authService.organizationId;

  applications: any[] = [];
  public scrollbarConfig: any;
  curPage: number;
  pageSize: number;
  noEntries: number[] = [5, 10, 25, 50, 100];
  lengthMenuText = this.translate
    .instant('Datatables.LengthMenu')
    .split('_MENU_');
  myAppGroups: any[] = [];
  isAddGroupActive: boolean;
  myAppGroupApplicationList: any[] = [];
  isMyAppGroup: boolean;
  currentMyAppGroupID: number;
  selectedGroup: MyAppGroupModel;

  constructor(
    public translate: TranslateService,
    private applicationStateService: ApplicationStateService,
    private authService: AuthService,
    private userApplicationService: UserApplicationService,
    private constantService: ConstantService,
    private modalService: NgbModal,
    private myAppGroupService: MyAppGroupService,
    private myAppGroupApplicationService: MyAppGroupApplicationService
  ) { }

  ngOnInit() {
    this.pageSize = this.applicationStateService.myApplicationState.pageSize;
    this.curPage = this.applicationStateService.myApplicationState.curPage;
    this.initialise();
  }

  initialise() {
    this.getAllUserApplications();
    this.getMyAppGroup();
    this.isAddGroupActive = false;
    this.myAppGroupApplicationList = [];
    this.isMyAppGroup = false;
    this.currentMyAppGroupID = null;
  }

  getAllUserApplications() {
    this.isAddGroupActive = false;
    this.myAppGroupApplicationList = [];
    this.isMyAppGroup = false;
    this.currentMyAppGroupID = null;
    this.userApplicationService
      .apiUserApplicationGetAllUserApplicationGet()
      .subscribe({
        next: (result) => (this.applications = result),
        error: (err) => {
          console.log(err);
        },
        complete: () => { },
      });
  }

  getAllGroupApplications(myAppGroup) {
    this.isAddGroupActive = false;
    this.myAppGroupApplicationList = [];
    var myAppGroupId = myAppGroup.myAppGroupId;
    this.isMyAppGroup = true;
    this.currentMyAppGroupID = myAppGroupId;
    this.selectedGroup = myAppGroup;
    this.myAppGroupApplicationService.apiMyAppGroupApplicationGetAllUserApplicationsbyMyGroupIdUserIdOrganizationIdMyAppGroupIdGet(this.userId, this.organizationId, myAppGroupId)
      .subscribe({
        next: (result) => (this.applications = result),
        error: (err) => {
          console.log(err);
        },
        complete: () => { },
      });
  }

  setAppStateURL(value) {
    const urlKey = value.relay_state ? 'relay_state' : 'thirdPartySignInUrl';
    let urlValue = value[urlKey];
    const objKeys = Object.keys(value);
    objKeys.forEach((element) => {
      if (element !== urlKey) {
        const newElement = `$${element}$`;
        urlValue = urlValue.replaceAll(newElement, value[element]);
      }
    });
    value[urlKey] = urlValue;
  }

  applicationDashboard(application) {
    if (!this.isAddGroupActive) {
      this.applicationDashBoardRedirect(application.attributesValue)
    }
    else {
      this.addApplicationToMyApplicationList(application)
    }
  }

  applicationDashBoardRedirect(appName) {
    const val = appName ? JSON.parse(appName) : undefined;
    this.setAppStateURL(val);
    if (val?.relay_state) {
      window.open(val.relay_state, '_blank');
    } else if (val?.thirdPartySignInUrl) {
      window.open(val.thirdPartySignInUrl, '_blank');
    }
  }

  getImageUrl(value: string) {
    if (value.startsWith('http')) {
      return value;
    } else if (!value.includes('assets/images')) {
      return this.constantService.imageUrl + value;
    }
    return value;
  }

  getTotalPages() {
    return Math.ceil(this.applications.length / this.pageSize);
  }

  setPageSizeOnChange(pageSize) {
    this.pageSize = pageSize;
    this.curPage = 1;
    this.applicationStateService.myApplicationState.pageSize = pageSize;
    this.applicationStateService.myApplicationState.curPage = 1;
  }



  getMyAppGroup() {
    this.myAppGroupService.apiMyAppGroupGetAllMyAppGroupsUserIdGet(this.userId).subscribe(data => {
      this.myAppGroups = data;
    })
  }

  enableAddMyAppGroup() {
    if (this.isAddGroupActive) {
      this.isAddGroupActive = false;
      this.myAppGroupApplicationList = [];
    }
    else {
      this.isAddGroupActive = true;
    }

  }
  addApplicationToMyApplicationList(application) {
    if (this.myAppGroupApplicationList.includes(application)) {
      const index = this.myAppGroupApplicationList.indexOf(application);
      if (index > -1) {
        this.myAppGroupApplicationList.splice(index, 1);
      }
    }
    else {
      this.myAppGroupApplicationList.push(application);
    }

  }

  openAddNewGroupModal() {
    const modalRef = this.modalService.open(NewGroupModalComponent, {
      size: 'md',
      windowClass: 'fadeInUp animated huge',
      backdrop: 'static',
    });
    // Set the values for modal
    modalRef.componentInstance.userId = this.userId;
    modalRef.result.then((result) => {
      if (result) {
        this.initialise();
      }
    })

  }

  openEditGroupModal() {
    const modalRef = this.modalService.open(EditGroupModalComponent, {
      size: 'md',
      windowClass: 'fadeInUp animated huge',
      backdrop: 'static',
    });
    // Set the values for modal
    modalRef.componentInstance.userId = this.userId;
    modalRef.componentInstance.myAppGroupmodel = this.selectedGroup;
  }

  openDeleteGroupModal() {
    const modalRef = this.modalService.open(DeleteGroupModalComponent, {
      size: 'md',
      windowClass: 'fadeInUp animated huge',
      backdrop: 'static',
    });
    // Set the values for modal
    modalRef.componentInstance.myAppGroupId = this.currentMyAppGroupID;
    modalRef.result.then((result) => {
      if (result) {
        this.initialise();
      }
    })

  }
  openAddApplicationToGroupModal() {
    const modalRef = this.modalService.open(AddApplicationToGroupModalComponent, {
      size: 'md',
      windowClass: 'fadeInUp animated huge',
      backdrop: 'static',
    });
    // Set the values for modal
    modalRef.componentInstance.userId = this.userId;
    modalRef.componentInstance.organizationId = this.organizationId;
    modalRef.componentInstance.groupList = this.myAppGroups;
    modalRef.componentInstance.applicationList = this.myAppGroupApplicationList;

    modalRef.result.then((result) => {
      if (result) {
        if(this.isMyAppGroup){
          this.getAllGroupApplications(this.selectedGroup);
        }
      }
    });
  }

  
  openDeleteMyAppGroupApplicationsModal() {
    const modalRef = this.modalService.open(DeleteMyAppGroupApplicationModalComponent, {
      size: 'md',
      windowClass: 'fadeInUp animated huge',
      backdrop: 'static',
    });
    // Set the values for modal
    modalRef.componentInstance.userId = this.userId;
    modalRef.componentInstance.organizationId = this.organizationId;
    modalRef.componentInstance.groupList = this.myAppGroups;
    modalRef.componentInstance.applicationList = this.myAppGroupApplicationList;
    modalRef.componentInstance.myAppGroupId = this.selectedGroup.myAppGroupId;
    modalRef.result.then((result) => {
      if (result) {
        this.getAllGroupApplications(this.selectedGroup);
      }
    })
  }



  selectAllApplication() {
    if (this.myAppGroupApplicationList.length == this.applications.length) {
      this.myAppGroupApplicationList = [];
    }
    else {
      this.myAppGroupApplicationList = this.applications;
    }
  }



}
