import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { User } from 'oidc-client';
import { AuthService } from 'src/app/authentication/auth.service';
import { AppSettingsService } from 'src/app/services/common/appsettings.service';
import { ConfigurationSettingsEnum, ConstantService, ThemeEnum, UserRoles } from 'src/app/services/common/constant.service';
import { OrganizationModel, OrganizationService } from 'src/app/services/organization';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AdminValidationModal } from 'src/app/user/admin-validation-modal/admin-validation-modal';
import { OrganizationSettingService } from 'src/app/services/organization';
import { FormBuilder, FormGroup } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs';
import { UsersService } from 'src/app/services/user';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  public activeLang = localStorage.getItem('locale') ?? 'en';
  showAdmin = this.appSettings.settings.adminMenu;

  public user: User;
  public organizations: any;
  public filteredOrganizations: any;
  public pagedOrg: any;
  public currentTenant: any;
  public defaultTenant: any;
  pageSize = 5;
  currentIndex = 1;
  totalPages = 1;

  languages = [
    { id: 'en', name: 'English', active: false },
    { id: 'es', name: 'Español', active: false },
    { id: 'fr', name: 'Français', active: false },
    { id: 'de', name: 'Deutsch', active: false },
  ];

  profileImageUrl: string;

  private adminValidationEnabled = false;

  tenantForm: FormGroup;

  constructor(
    public appSettings: AppSettingsService,
    private constantService: ConstantService,
    private authService: AuthService,
    private organizationService: OrganizationService,
    private organizationSettingService: OrganizationSettingService,
    public router: Router,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private userService: UsersService
  ) { }

  async ngOnInit() {
    this.profileImageUrl = this.constantService.getImageFromServer(ThemeEnum.profileLogo) ?? "assets/images/user.png";
    this.user = await this.authService.getUser();

    if (this.canManageOtherTenants()) {

      this.currentTenant = this.constantService.mspTenant;
      this.organizationService.apiOrganizationGetOrganizationAllGet().subscribe(
        {
          next: (result: OrganizationModel[]) => {
            this.organizations = result?.sort((a, b) => a.organizationName?.localeCompare(b.organizationName));
            this.defaultTenant = this.organizations?.find(f => f.mnemonic == this.constantService.getDefaultTenant());
            this.filteredOrganizations = this.organizations;
            this.setTenants();
          },
          error: (error) => {
            console.log(error);

          }
        }
      );
    }

    if(this.authService.userType != 'User'){
      this.getAdminValidationEnabled();
    }

    this.tenantForm = this.fb.group({
      search: ['']
    });
  }

  setTenant(tenant) {
    this.currentTenant = tenant;
    this.constantService.setMspTenant(tenant);
    this.authService.reloadPage();

  }

  setTenants() {
    
    this.totalPages = Math.ceil(this.filteredOrganizations.length / this.pageSize);
    var trimLeft: any = (this.currentIndex - 1) * this.pageSize;
    var trimRight: any = this.currentIndex * this.pageSize;
    (trimRight < this.filteredOrganizations[this.filteredOrganizations.length - 1]) ? this.currentIndex * this.pageSize : this.filteredOrganizations[(this.filteredOrganizations.length - 1)];
    this.pagedOrg = this.filteredOrganizations.slice(trimLeft, trimRight);

    this.tenantForm.controls.search.valueChanges.pipe(debounceTime(300), distinctUntilChanged()).subscribe(val => {
      val = val.toLowerCase();
      this.filteredOrganizations = this.organizations.filter(x => x.organizationName.toLowerCase().includes(val));
      this.currentIndex = 1;
      this.setTenants();
    })
  }

  prevTenants() {
    this.currentIndex--;
    if (this.currentIndex < 1) this.currentIndex = 1;

    this.setTenants();
  }

  nextTenants() {
    this.currentIndex++;
    if (this.currentIndex > this.totalPages) {
      this.currentIndex--;
    }
    this.setTenants();
  }

  closeMenu(e) {
    e.target.closest('.dropdown').classList.remove('show');
    e.target.closest('.dropdown .dropdown-menu').classList.remove('show');
  }

  toggleSidebar(event) {
    event.preventDefault();
    this.appSettings.settings.showLeftMenu =
      !this.appSettings.settings.showLeftMenu;
    if (window.matchMedia('(min-width: 992px)').matches) {
      document.querySelector('body')!.classList.toggle('az-sidebar-hide');
    } else {
      document.querySelector('body')!.classList.toggle('az-sidebar-show');
    }
  }

  public isUser() {
    const userType = this.authService.userType;
    return (userType && userType.toLowerCase() === UserRoles.User.toLowerCase());
  }

  public canManageOtherTenants() {
    const userType = this.authService.userType;
    return (userType && (userType.toLowerCase() === UserRoles.SuperAdmin.toLowerCase() || userType.toLowerCase() == UserRoles.MspAdmin));
  }

  async setLanguage(lang) {
    this.activeLang = lang.id;
    localStorage.setItem('locale', lang.id);
    await this.authService.loadLanguages();

  }

  private getAdminValidationEnabled() {
    this.userService.apiUsersCheckAdminValidationStatusOrganizationIdUserIdPut(this.authService.user.profile.OrganizationId,  this.authService.user.profile.UserID).subscribe((data) => {
      if (data) {
        this.adminValidationEnabled = true;
      } else {
        this.adminValidationEnabled = false;
      }
    });
  }

  setMenu() {
    this.getAdminValidationEnabled();

    if(this.adminValidationEnabled && !this.appSettings.settings.adminMenu){
      const modalRef = this.modalService.open(AdminValidationModal, { size: 'md' });
      modalRef.result.then((isAdminValidated) => {
        if (isAdminValidated) {
          this.toggleMenu();
        }
      }).catch((error) => {
          console.error('Error in admin validation modal:', error);
      });
    }else{
      this.toggleMenu();
    }
  }

  toggleMenu(){
    this.appSettings.settings.adminMenu = !this.appSettings.settings.adminMenu;
    if (this.appSettings.settings.adminMenu) {
        this.router.navigateByUrl('/dashboard');
    } else {
        this.router.navigateByUrl('/myapplications');
    }
  }

  public async signout() {
    await this.authService.signOut();
  }

  filterTenants(event: any) {
    console.log(event.target.value);
  }

  debounce = (func, timeout) => {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => { func.apply(this, args); }, timeout);
    }
  }
}
