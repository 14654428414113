/**
 * User Microservice
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface UserDirectoryModel { 
    userDirectoryId?: number;
    userId?: number | null;
    organizationDirectoryID?: number | null;
    organizationID?: number | null;
    directoryName?: string | null;
    uniqueID?: string | null;
    immutableId?: string | null;
    domain?: string | null;
    modifiedDate?: string;
    modifiedBy?: string | null;
}

