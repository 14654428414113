import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-policy-modal',
  templateUrl: './policy-modal.component.html',
  styleUrls: ['./policy-modal.component.scss']
})
export class PolicyModalComponent implements OnInit {

  policyData: policyData
;
  private subscriptions: Subscription = new Subscription;

  constructor(
    public ngbActiveModal: NgbActiveModal,
    private httpClient: HttpClient
  ) { }

  ngOnInit() {
    this.subscriptions.add(
      this.httpClient.get<policyData>("/assets/json/policy.json").subscribe(data => {
        this.policyData = data
      })
    )
  }


  close() {
    this.ngbActiveModal.close(false);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

}

interface policyData {
  header: string,
  detail: string
}
