/**
 * User Microservice
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { MembersDelta } from './membersDelta';


export interface GroupModel { 
    groupId?: number;
    groupName: string;
    description?: string | null;
    sourceOrganizationId?: number;
    organizationId: number;
    mnemonic: string;
    isActive?: boolean;
    modifiedBy?: string | null;
    createdBy?: string | null;
    createdDate?: string;
    modifiedDate?: string;
    isDeleted?: boolean;
    distinguishedName?: string | null;
    organizationDirectoryId?: number;
    sourceDirectoryID?: number | null;
    modifiedByDirectoryID?: number | null;
    membersdelta?: Array<MembersDelta> | null;
    isEnforcedSecondFactor?: boolean | null;
}

