import { Injectable } from '@angular/core';
import { UserModel } from '../model/models';
import { UserAddModelUsersGroups } from '../model/userAddModelUsersGroups';

@Injectable({
  providedIn: 'root'
})
export class UserstateService {

  userModel = {} as UserModel;
  usersGroups = {} as UserAddModelUsersGroups;


  _selectedUser = {} as UserModel;

  set selectedUser(user: UserModel) {
    localStorage.setItem('selectedUser', JSON.stringify(user));
    this._selectedUser = user;
  }

  get selectedUser(): UserModel {
    return this._selectedUser;
  }

  constructor() {
    this.selectedUser = JSON.parse(localStorage.getItem('selectedUser')) || {} as UserModel;
  }
}
