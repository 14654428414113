export * from './account.service';
import { AccountService } from './account.service';
export * from './authenticationType.service';
import { AuthenticationTypeService } from './authenticationType.service';
export * from './certificate.service';
import { CertificateService } from './certificate.service';
export * from './hardwareToken.service';
import { HardwareTokenService } from './hardwareToken.service';
export * from './legacySecurEnvoyApp.service';
import { LegacySecurEnvoyAppService } from './legacySecurEnvoyApp.service';
export * from './loginHistory.service';
import { LoginHistoryService } from './loginHistory.service';
export * from './questionSecretAnswer.service';
import { QuestionSecretAnswerService } from './questionSecretAnswer.service';
export * from './redirect.service';
import { RedirectService } from './redirect.service';
export * from './settings.service';
import { SettingsService } from './settings.service';
export * from './userAuthCredential.service';
import { UserAuthCredentialService } from './userAuthCredential.service';
export * from './userCredential.service';
import { UserCredentialService } from './userCredential.service';
export * from './userSession.service';
import { UserSessionService } from './userSession.service';
export * from './userToken.service';
import { UserTokenService } from './userToken.service';
export * from './version.service';
import { VersionService } from './version.service';
export const APIS = [AccountService, AuthenticationTypeService, CertificateService, HardwareTokenService, LegacySecurEnvoyAppService, LoginHistoryService, QuestionSecretAnswerService, RedirectService, SettingsService, UserAuthCredentialService, UserCredentialService, UserSessionService, UserTokenService, VersionService];
