import { Component, OnDestroy, OnInit, Input } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { MyAppGroupModel, MyAppGroupService } from 'src/app/services/user';


@Component({
  selector: 'app-new-group-modal',
  templateUrl: './new-group-modal.component.html',
  styleUrls: ['./new-group-modal.component.scss']
})
export class NewGroupModalComponent implements OnInit {

  public addMyAppGroupForm: FormGroup;
  public myAppGroupmodel: MyAppGroupModel;
  @Input() userId;

  constructor(
    private fb: FormBuilder,
    private toastrService: ToastrService,
    private translateService: TranslateService,
    public activeModal: NgbActiveModal,
    private myAppGroupService: MyAppGroupService
  ) { }

  ngOnInit(): void {
    this.myAppGroupmodel = {
      "userId": 0,
      "groupName": "",
    };
    this.initForm();
    this.myAppGroupmodel.userId = this.userId;
  }

  private initForm() {
    this.addMyAppGroupForm = this.fb.group({
      groupName: [this.myAppGroupmodel.groupName, Validators.compose([Validators.required])],
    });
  }

  public save() {
    if (!this.addMyAppGroupForm.controls.groupName.errors?.required) {
      this.myAppGroupmodel.groupName = this.addMyAppGroupForm.value.groupName;
      this.myAppGroupService.apiMyAppGroupAddMyAppGroupPost(this.myAppGroupmodel).subscribe(data => {
        this.toastrService.success(this.translateService.instant('Action.Success'));
        this.activeModal.close(true);
      })
    }

  }

}



